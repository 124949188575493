<template>
  <HorizontalStack
    id="code-six-digit-code-input-container"
    class="max-w-72 w-full"
    :spacing="2"
    @click="setActiveField"
    @keyup.space="setActiveField"
  >
    <div
      v-for="n in 6"
      :key="n"
      :id="`code-${n-1}`"
      class="code-input border border-border rounded-lg
      shadow-md p-2 pt-2 w-full text-center h-12 text-2xl"
      :class="{ 'ring-error shadow-error-light ring-2': error,
      'bg-active ring-foreground ring-2': n-1 === activeIndex }"
      tabindex="0"
      @click.stop="setActiveField"
      @keyup.space="setActiveField"
    >
      {{ codeChars[n-1] }}
    </div>
  </HorizontalStack>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  watch,
  onMounted, onUnmounted,
} from 'vue';

export default defineComponent({
  name: 'CodeInput',
  props: {
    modelValue: String,
    error: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const codeChars = ref(Array(6).fill(''));
    const activeIndex = ref(0);

    watch(() => props.modelValue, (newVal) => {
      if (newVal) {
        const chars = newVal.split('');
        chars.forEach((char, index) => {
          codeChars.value[index] = char;
        });
        activeIndex.value = chars.length < 6 ? chars.length : 5;
      }
    });

    const setActiveField = () => {
      activeIndex.value = codeChars.value.filter((element) => element !== '').length;
      document.getElementById('code-six-digit-code-input-container')?.focus();
    };

    const handleContainerClick = (event: MouseEvent) => {
      if (!(event.target as Element).id.startsWith('code-')) {
        document.getElementById('code-six-digit-code-input-container')?.blur();
        activeIndex.value = -1;
      }
    };

    const updateActiveIndex = (direction: number) => {
      if (direction === 1 && activeIndex.value < 5) {
        activeIndex.value += 1;
      } else if (direction === -1) {
        if (codeChars.value[activeIndex.value] === '' && activeIndex.value > 0) {
          codeChars.value[activeIndex.value - 1] = '';
          activeIndex.value -= 1;
        } else {
          codeChars.value[activeIndex.value] = '';
        }
      }
    };

    const handlePaste = async (event: KeyboardEvent) => {
      if (activeIndex.value < 0) {
        return;
      }
      if ((event.ctrlKey || event.metaKey) && event.key === 'v') {
        event.preventDefault();
        const text = await navigator.clipboard.readText();
        if (/^\d{6}$/.test(text)) {
          text.split('').forEach((char, index) => {
            codeChars.value[index] = char;
          });
          activeIndex.value = 5;
          emit('update:modelValue', codeChars.value.join(''));
        }
      }
    };

    const handleKeydown = (event: KeyboardEvent) => {
      if (activeIndex.value < 0) {
        return;
      }
      const { key } = event;
      if (key.match(/^[0-9]$/) && activeIndex.value < 6) {
        if (codeChars.value.filter((element) => element !== '').length === 6) {
          return;
        }
        codeChars.value[activeIndex.value] = key;
        updateActiveIndex(1);
      } else if (key === 'Backspace') {
        updateActiveIndex(-1);
      }
      emit('update:modelValue', codeChars.value.join(''));
    };

    const handleKeydownExtended = (event: KeyboardEvent) => {
      handleKeydown(event);
      handlePaste(event);
    };

    onMounted(() => {
      activeIndex.value = 0;
      window.addEventListener('click', handleContainerClick);
      window.addEventListener('keydown', handleKeydownExtended);
    });

    onUnmounted(() => {
      window.removeEventListener('click', handleContainerClick);
      window.removeEventListener('keydown', handleKeydownExtended);
    });

    function focus() {
      document.getElementById('code-six-digit-code-input-container')?.focus();
    }

    return {
      codeChars,
      handleKeydown,
      setActiveField,
      activeIndex,
      handleContainerClick,
      focus,
    };
  },
});
</script>

<style scoped>
.code-input,
.code-six-digit-code-input-container div:focus {
  outline: none;
}
</style>
