<template>
  <div :class="heightClass">
    <span class="invisible">Spacer</span>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    size: {
      type: String as PropType<'tiny' | 'small' | 'medium' | 'large' | 'stretch' | 'minuscule' | 'extrasmall'>,
      default: 'medium',
    },
  },
  setup(props) {
    const heightClass = computed(() => {
      switch (props.size) {
        case 'minuscule':
          return 'h-2 w-2';
        case 'tiny':
          return 'h-6 w-6';
        case 'extrasmall':
          return 'h-8 w-8';
        case 'small':
          return 'h-12 w-12';
        case 'large':
          return 'h-48 w-48';
        case 'stretch':
          return 'flex-grow w-0 h-0';
        default:
          return 'h-24 w-24';
      }
    });
    return {
      heightClass,
    };
  },
});
</script>
