import { RouteLocationNormalizedLoaded } from 'vue-router';

const getNavigationArea = (route: RouteLocationNormalizedLoaded) => {
  if (route.path.includes('content/imagepool')) {
    return 'imagepool';
  } else if (route.path.includes('content/iconpool')) {
    return 'iconpool';
  } else if (route.path.includes('content/powerpoint')) {
    return 'powerpoint';
  } else {
    return '';
  }
};

const isFavorites = (route: RouteLocationNormalizedLoaded) => {
  return route.path.includes(`/favorites/${route.params.type}`)
    || route.path.includes(`/favorites/onlineImages`)
}

const isOnlineImages = (route: RouteLocationNormalizedLoaded) => {
  return route.path.includes('imagepool/onlineImages') || route.params.type === 'onlineImages';
};

const isLastUsed = (route: RouteLocationNormalizedLoaded) => {
  return route.path.includes(`/last-used/${route.params.type}`)

}

export {
  getNavigationArea,
  isOnlineImages,
  isFavorites,
  isLastUsed,
}
