import { useStore } from 'vuex';
import { computed, inject, provide, ref, watch } from 'vue';
import LoadingContext from '@/types/LoadingContext';


const useLoadingState = () => {
  const store = useStore();

  const screenContext = ref<LoadingContext | null>(null);

  const startLoading = async (context?: LoadingContext) => {
    await store.dispatch('startLoading', context ?? screenContext.value);
  };

  const stopLoading = async (context?: LoadingContext) => {
    await store.dispatch('stopLoading', context ?? screenContext.value);
  };

  const isLoading = computed(() => store.getters.isLoading(screenContext.value));

  watch(screenContext, (context) => {
    if (context) {
      provide('loadingContext', context);
    }
  });

  watch(() => inject('loadingContext') as LoadingContext | null, (newValue) => {
    screenContext.value = newValue;
  });

  return {
    startLoading,
    stopLoading,
    loadingContext: screenContext,
    isScreenLoading: isLoading,
  };
};

export default useLoadingState;
