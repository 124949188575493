import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "whitespace-nowrap" }
const _hoisted_2 = { class: "text-sm text-foreground-light" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VerticalStack = _resolveComponent("VerticalStack")!
  const _component_simple_divider = _resolveComponent("simple-divider")!
  const _component_MenuItem = _resolveComponent("MenuItem")!
  const _component_SimpleDivider = _resolveComponent("SimpleDivider")!
  const _component_LogoutButtonComponent = _resolveComponent("LogoutButtonComponent")!
  const _component_VersionInfo = _resolveComponent("VersionInfo")!

  return (_openBlock(), _createBlock(_component_MenuItem, {
    padded: false,
    icon: "/icons/menu.svg"
  }, {
    submenu: _withCtx(() => [
      _createVNode(_component_VerticalStack, {
        spacing: 2,
        class: "p-4"
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.userName), 1 /* TEXT */),
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.userMail), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_simple_divider, { class: "px-4 w-full" }),
      _createVNode(_component_MenuItem, {
        onClick: _ctx.openProfile,
        exact: false,
        permissions: "USER_READ_SELF",
        class: "w-full",
        icon: "/icons/user.svg",
        label: _ctx.$t('screens.main.buttons.profile')
      }, null, 8 /* PROPS */, ["onClick", "label"]),
      (_ctx.$userAllowed('ORGANIZATION_EDIT'))
        ? (_openBlock(), _createBlock(_component_SimpleDivider, {
            key: 0,
            class: "w-full"
          }))
        : _createCommentVNode("v-if", true),
      _createVNode(_component_LogoutButtonComponent, { class: "mt-4" }),
      _createVNode(_component_VersionInfo, { class: "pt-4" })
    ]),
    _: 1 /* STABLE */
  }))
}