<template>
  <img
    src="/icons/loading.svg"
    alt="Loading..."
    class="animate-spin"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  setup() {
    return {};
  },
});
</script>
