class MSALCodeVerifierService {
  static async getCodeVerifierAndChallenge(): Promise<[string, string]> {
    const codeVerifier = MSALCodeVerifierService.generateCodeVerifier();
    const codeChallenge = await MSALCodeVerifierService.generateCodeChallenge(codeVerifier);
    return [codeVerifier, codeChallenge];
  }

  private static generateCodeVerifier(): string {
    const array = new Uint8Array(32);
    window.crypto.getRandomValues(array);
    // eslint-disable-next-line no-bitwise
    return Array.from(array, (byte) => (`0${(byte & 0xFF).toString(16)}`).slice(-2)).join('');
  }

  private static async generateCodeChallenge(codeVerifier: string): Promise<string> {
    const encoder = new TextEncoder();
    const data = encoder.encode(codeVerifier);
    const hash = await crypto.subtle.digest('SHA-256', data);
    return MSALCodeVerifierService.base64urlencode(new Uint8Array(hash));
  }

  private static base64urlencode(buffer: Uint8Array): string {
    let str = '';
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < buffer.byteLength; i++) {
      str += String.fromCharCode(buffer[i]);
    }
    return btoa(str).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
  }
}

export default MSALCodeVerifierService;
