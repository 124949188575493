<template>
  <VerticalStack :spacing="null"
    @click.prevent.stop="expanded = !expanded"
    :class="classForLevel"
  >
    <horizontal-stack class="px-2 pt-3 w-full clickable" alignment="baseline">
      <img
        src="/icons/right-arrow.svg"
        alt="right arrow"
        class="size-3"
        :class="{ 'rotate-90': expanded }"
        v-if="canShowChildren"
      />
      <div class="w-3" v-else />
      <img src="/icons/folder.svg" alt="Folder icon" class="size-4" />
      <h3 class="">{{ item.name }}</h3>
      <stack-spacer size="stretch" />
      <default-button
        type="link"
        v-if="canBeSelected"
        @click.prevent.stop="moveToCategory"
      >
        {{ $t('screens.files.move.buttons.move') }}
      </default-button>
    </horizontal-stack>
    <ManageContentCategoryTreeItemView
      v-for="(child, index) in children"
      :key="child.slug"
      :item="child"
      class="w-full"
      :show-separator="index < children.length - 1"
      @move="($event) => $emit('move', $event)"
      :current-category="currentCategory"
      :children-filter="childrenFilter"
    />
    <simple-divider class="w-full" v-if="showSeparator" />
  </VerticalStack>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue';
import VerticalStack from '@/components/layout/VerticalStack.vue';
import ContentCategoryTreeItem from '@/types/ContentCategoryTreeItem';
import SimpleDivider from '@/components/viewelements/SimpleDivider.vue';
import HorizontalStack from '@/components/layout/HorizontalStack.vue';
import DefaultButton from '@/components/controls/DefaultButton.vue';
import StackSpacer from '@/components/layout/StackSpacer.vue';
import useAlert from '@/composables/use-alert';
import { useI18n } from 'vue-i18n';
import ContentCategory from '@/types/ContentCategory';

export default defineComponent({
  components: {
    StackSpacer, DefaultButton, HorizontalStack, SimpleDivider, VerticalStack},
  emits: {
    move: (item: ContentCategoryTreeItem) => true,
  },
  props: {
    item: {
      type: Object as PropType<ContentCategoryTreeItem>,
      required: true,
    },
    showSeparator: {
      type: Boolean,
      required: true,
    },
    childrenFilter: {
      type: Function as PropType<(items: ContentCategoryTreeItem) => boolean>,
      required: false,
      default: () => () => true,
    },
    currentCategory: {
      type: String,
      required: true,
    }
  },
  setup(props, { emit }) {

    const expanded = ref(false);
    const canBeSelected = computed(() => {
      return props.currentCategory !== props.item.slug;
    });
    const children = computed(() => {
      return expanded.value ? props.item.children.filter(props.childrenFilter) : [];
    });
    const canShowChildren = computed(() => props.item.children.filter(props.childrenFilter).length > 0);
    const classForLevel = computed(() => {
      switch (props.item.level) {
        case 0:
          return 'pl-0';
        case 1:
          return 'pl-4';
        case 2:
          return 'pl-8';
        case 3:
          return 'pl-12';
        case 4:
          return 'pl-16';
        case 5:
          return 'pl-20';
        case 6:
          return 'pl-24';
        case 7:
          return 'pl-28';
        case 8:
          return 'pl-32';
        case 9:
          return 'pl-36';
        case 10:
          return 'pl-40';
        default:
          return 'pl-0';
      }
    });

    const moveToCategory = () => {
      emit('move', props.item);
    };

    return {
      expanded,
      children,
      classForLevel,
      canShowChildren,
      moveToCategory,
      canBeSelected,
    };
  },
});
</script>
