import { useStore } from 'vuex';
import {
  AlertButton,
  AlertButtonStyle,
  AlertType,
} from '@/types/AlertInfo';
import { i18n } from '@/i18n/i18n-setup';
import LoadingContext from '@/types/LoadingContext';

const useAlert = () => {
  const store = useStore();

  const showAlert = (
    {
      title = null,
      message = null,
      type = AlertType.Normal,
      textLabel = null,
      inputType = null,
      buttons = [],
      prefill = null,
    }:
      {
        title?: string | null;
        message?: string | null;
        type?: AlertType;
        textLabel?: string | null;
        inputType?: string | null;
        buttons?: AlertButton[];
        prefill?: string | null;
      },
  ) => {
    store.dispatch(
      'showAlert',
      {
        title: title ? i18n.global.t(title) : null,
        message: message ? i18n.global.t(message) : null,
        type,
        textLabel: textLabel ? i18n.global.t(textLabel) : null,
        inputType,
        buttons,
        prefill: prefill ? i18n.global.t(prefill) : null,
      },
    );
  };

  const closeAlert = () => {
    store.dispatch('closeAlert');
  };

  const createAlertButton = ({
    title,
    type = AlertButtonStyle.Primary,
    autoLoadingState = 'global',
    action = async () => { /* default action  */ },
  }: {
    title: string;
    type?: AlertButtonStyle;
    autoLoadingState?: LoadingContext | null;
    action?: (input: string | null) => Promise<boolean | void>;
  }) => ({
    title: i18n.global.t(title),
    type,
    isCancel: false,
    autoLoadingState,
    action,
  });

  const createPredefinedButton = (
    title: string,
    type: AlertButtonStyle,
    isCancel: boolean,
    action: (input: string | null) => Promise<boolean | void>,
  ) => ({
    title: i18n.global.t(title),
    type,
    isCancel,
    autoLoadingState: 'global' as LoadingContext | null,
    action,
  });

  const cancel = createPredefinedButton(
    i18n.global.t('generic.cancel'),
    AlertButtonStyle.Secondary,
    true,
    async () => { /* default action  */ }
  );

  const no = createPredefinedButton(
    i18n.global.t('generic.no'),
    AlertButtonStyle.Secondary,
    true,
    async () => { /* default action  */ }
  );

  const yes = (action: (input: string | null) => Promise<boolean | void>) => createPredefinedButton(
    i18n.global.t('generic.yes'),
    AlertButtonStyle.Primary,
    false,
    action
  );

  const setAlertError = (message: string | null) => {
    if (!message) {
      store.dispatch('setAlertError', null);
      return;
    }
    store.dispatch('setAlertError', i18n.global.t(message));
  };

  return {
    createAlertButton,
    showAlert,
    closeAlert,
    setAlertError,
    cancel,
    no,
    yes,
  };
};

export default useAlert;
