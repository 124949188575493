import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createBlock($setup["MenuDetailView"], null, {
    menu: _withCtx(() => [
      _createVNode($setup["MenuItem"], {
        to: "/admin/me",
        icon: "/icons/user.svg",
        label: _ctx.$t('screens.profile.title')
      }, null, 8 /* PROPS */, ["label"]),
      (_ctx.$user()?.azureSSO === false)
        ? (_openBlock(), _createBlock($setup["MenuItem"], {
            key: 0,
            icon: "/icons/key.svg",
            to: "/admin/me/password",
            permissions: "USER_CHANGE_AUTHENTICATION",
            label: _ctx.$t('screens.profile.authentication.title')
          }, null, 8 /* PROPS */, ["label"]))
        : _createCommentVNode("v-if", true),
      _createVNode($setup["MenuItem"], {
        icon: "/icons/devices.svg",
        to: "/admin/me/active-sessions",
        permissions: "USER_ACTIVE_LOGINS_READ_SELF",
        label: _ctx.$t('screens.profile.activeLogins.title')
      }, null, 8 /* PROPS */, ["label"])
    ]),
    content: _withCtx(() => [
      _createVNode(_component_router_view)
    ]),
    _: 1 /* STABLE */
  }))
}