type SettingsKey = 'displayStyle' | 'loginStateIntention' | 'uniqueClientIdentifier';
type DisplayStyle = 'list' | 'grid';
type LoginStateIntention = 'intentionallyLoggedOut' | 'selfLoggedIn';

class LocalSettingsService {
  static async getDisplayStyle(defaultStyle?: DisplayStyle): Promise<DisplayStyle> {
    const storedStyle = localStorage.getItem('displayStyle') as DisplayStyle;
    return storedStyle !== null ? storedStyle : (defaultStyle || 'grid');
  }

  static async setDisplayStyle(value: DisplayStyle): Promise<void> {
    localStorage.setItem('displayStyle', value);
  }

  static async getUniqueClientIdentifier(): Promise<string> {
    return localStorage.getItem('uniqueClientIdentifier') || '';
  }

  static async setUniqueClientIdentifier(value: string): Promise<void> {
    localStorage.setItem('uniqueClientIdentifier', value);
  }

  static async getLoginStateIntention(): Promise<LoginStateIntention | null> {
    return localStorage.getItem('loginStateIntention') as LoginStateIntention | null;
  }

  static async setLoginStateIntention(value: LoginStateIntention | null): Promise<void> {
    if (value) {
      localStorage.setItem('loginStateIntention', value);
    } else {
      localStorage.removeItem('loginStateIntention');
    }
  }
}

export {
  LocalSettingsService,
  DisplayStyle,
}
