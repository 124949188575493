import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HorizontalStack = _resolveComponent("HorizontalStack")!

  return (_openBlock(), _createBlock(_component_HorizontalStack, {
    class: _normalizeClass(["w-full sm:px-8 px-4 grow-0 shrink-0", [
      $props.size === 'small' ? 'h-10' : 'h-14',
    ]]),
    alignment: "middle",
    spacing: $props.spacing
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["class", "spacing"]))
}