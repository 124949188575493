<template>
  <p>{{ $t('screens.registration.verified.text') }}</p>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import MeService from '@/services/MeService';
import { useRoute, useRouter } from 'vue-router';

export default defineComponent({
  name: 'RegisterEmailVerifiedView',
  setup() {
    const route = useRoute();
    const router = useRouter();
    MeService.verifyEmail(route.params.token as string)
      .then(() => {
        router.push('/');
      })
      .catch((error) => {
        console.error(error);
      });

    return {};
  },
});
</script>
