<template>
  <HorizontalStack alignment="middle" :spacing="2">
    <input
      type="checkbox"
      v-model="checked"
      @click.stop
      :class="{ 'w-5 h-5': size === 'large',}"
    />
    <span>{{ label }}</span>
  </HorizontalStack>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, watch } from 'vue';

export default defineComponent({
  props: {
    label: String,
    modelValue: Boolean,
    size: {
      type: String as PropType<'small' | 'large'>,
      default: 'small',
    },
  },
  setup(props, { emit }) {
    const checked = ref(props.modelValue);

    checked.value = props.modelValue;

    watch(() => checked.value, (newVal) => {
      emit('update:modelValue', newVal);
    });

    watch(() => props.modelValue, (newVal) => {
      checked.value = newVal;
    });

    return { checked, label: props.label };
  },
});
</script>
