// cookie-helpers.ts

type CookieOptions = {
  expires?: number | Date; // Number (days) or Date object
  path?: string;
  domain?: string;
  secure?: boolean;
};

export function setCookie(name: string, value: string, options: CookieOptions = {}): void {
  let cookieString = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;

  if (options.expires) {
    const expiresDate = typeof options.expires === 'number'
      ? new Date(Date.now() + options.expires * 864e5)
      : options.expires;
    cookieString += `; expires=${expiresDate.toUTCString()}`;
  }

  if (options.path) {
    cookieString += `; path=${options.path}`;
  }

  if (options.domain) {
    cookieString += `; domain=${options.domain}`;
  }

  if (options.secure) {
    cookieString += '; secure';
  }

  document.cookie = cookieString;
}

export function getCookie(name: string): string | null {
  const nameEQ = `${encodeURIComponent(name)}=`;
  const cookies = document.cookie.split('; ');

  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    if (cookie.startsWith(nameEQ)) {
      return decodeURIComponent(cookie.substring(nameEQ.length));
    }
  }

  return null;
}

export function deleteCookie(name: string, path: string = '/', domain?: string) {
  setCookie(name, '', { expires: -1, path, domain });
}
