<template>
  <router-link :to="mainPath" class="text-foreground">
    <HorizontalStack alignment="middle">
      <img
        v-if="logoUrl && !imageLoadError"
        :src="logoUrl"
        :class="logoClass"
        @error="onImageLoadError"
        alt="Logo"
      />
      <img
        v-else
        src="/logo-without-claim.png"
        :class="logoClass"
        @error="onImageLoadError"
        alt="Logo"
      />
      <span
        class="text-foreground-light text-sm"
        v-if="!inOfficeAddin"
      >
        Admin
      </span>
    </HorizontalStack>
  </router-link>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { api } from '@/config';
import { useStore } from 'vuex';
import { inOfficeAddin } from '@/office-checker';
import VerticalStack from '@/components/layout/VerticalStack.vue';
import { getRedirectBaseLogic } from '@/router/addin-routes';

export default defineComponent({
  components: {VerticalStack},
  setup() {
    const store = useStore();
    const mainPath = computed(() => (inOfficeAddin ? getRedirectBaseLogic() : '/admin'));
    const logoClass = computed(() => (inOfficeAddin ? 'logo' : 'logo-small'));

    const logoUrl = computed(
      () => (store.state.user?.organizationSlug ? `${api.v1}/${store.state.user.organizationSlug}/logo` : undefined),
    );

    const imageLoadError = ref(false);
    const onImageLoadError = () => {
      imageLoadError.value = true;
    };

    return {
      logoUrl,
      onImageLoadError,
      imageLoadError,
      inOfficeAddin,
      mainPath,
      logoClass,
    };
  },
});
</script>

<style scoped>
  .logo {
    @apply max-h-6 max-w-80;
  }
  .logo-small {
    @apply max-h-6 max-w-80;
  }
</style>
