import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex flex-row", [_ctx.spacingClass, {
      'items-start': _ctx.alignment === 'top',
      'items-center': _ctx.alignment === 'middle',
      'items-end': _ctx.alignment === 'bottom',
      'items-baseline': _ctx.alignment === 'baseline',
      'items-stretch': _ctx.alignment === 'stretch',
    }]])
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 2 /* CLASS */))
}