import { App } from 'vue';
import { Store } from 'vuex';
import User from '@/types/User';

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $user: () => User | null;
  }
}

export default {
  install(app: App, options: { store: Store<any> }) {
    app.config.globalProperties.$user =
      (): User | null => (options.store.state.user ? options.store.state.user as User : null);
  },
};
