interface PageResult<T> {
  content: T[];
  totalPages: number;
  totalElements: number;
  last: boolean;
  first: boolean;
  numberOfElements: number;
  size: number;
  number: number;
}

interface Identifiable {
  id: number;
}

function replacePagedItem<T extends Identifiable>(pageResult: PageResult<T>, newItem: T): void {
  const index = pageResult.content.findIndex(item => item.id === newItem.id);
  if (index !== -1) {
    pageResult.content[index] = newItem;
  }
}

class EmptyPageResult<T> implements PageResult<T> {
  content: T[];

  totalPages: number;

  totalElements: number;

  last: boolean;

  first: boolean;

  numberOfElements: number;

  size: number;

  number: number;

  constructor() {
    this.content = [];
    this.totalPages = 0;
    this.totalElements = 0;
    this.last = true;
    this.first = true;
    this.numberOfElements = 0;
    this.size = 0;
    this.number = 0;
  }

  static empty<Type = any>(): PageResult<Type> {
    return new EmptyPageResult<Type>();
  }
}

export { PageResult, EmptyPageResult, replacePagedItem };
