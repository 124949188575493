import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "border border-border mx-auto bg-background rounded-lg shadow-2xl my-auto w-5/6 p-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createVNode($setup["OverlayBackground"], { class: "z-50" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "default")
        ])
      ]),
      _: 3 /* FORWARDED */
    })
  ]))
}