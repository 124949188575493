import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: 'organizations',
    name: 'su-organizations',
    component: () => import('@/views/superuser/organizations/OrganizationsView.vue'),
    children: [
      {
        path: '',
        name: 'su-organizations-list',
        component: () => import('@/views/superuser/organizations/OrganizationsListView.vue'),
      },
      {
        path: ':slug',
        name: 'su-organizations-view',
        component: () => import('@/views/superuser/organizations/OrganizationDetailsView.vue'),
      }
    ]
  }
];

export default routes;
