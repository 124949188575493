import { Ref } from 'vue';
import LoadingContext from '@/types/LoadingContext';

enum AlertType {
  Normal = 'normal',
  Code = 'code',
  Text = 'text',
}

enum AlertButtonStyle {
  Primary = 'primary',
  Secondary = 'secondary',
  Destructive = 'destructive',
  Constructive = 'constructive',
  Link = 'link',
}

interface AlertButton {
  title: string;
  type: AlertButtonStyle;
  isCancel: Boolean;
  autoLoadingState: LoadingContext | null;
  action: (input: string) => Promise<boolean | void>;
}
interface AlertInfo {
  title: string | null;
  message?: string | null;
  type: AlertType;
  textLabel?: string
  inputType?: string | null;
  buttons: AlertButton[];
  prefill: string | null;
}

export {
  AlertInfo,
  AlertButton,
  AlertType,
  AlertButtonStyle,
};
