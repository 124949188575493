<template>
  <div :spacing="null">
    <input
      ref="inputElement"
      v-if="type !== 'textarea'"
      class="default-input p-2 w-full"
      :class="{ 'border-error shadow-error-light': error }"
      :type="type"
      :placeholder="placeholder"
      :value="modelValue"
      :maxlength="maxLength"
      :autocomplete="autocomplete"
      :accept="type !== 'textarea' ? accept : null"
      @input="$emit('update:modelValue', $event.target.value)"
      @change="$emit('change', $event)"
    />
    <textarea
      ref="textareaElement"
      v-else
      class="default-input p-2 w-full"
      :class="{ 'border-error shadow-error-light': error }"
      :placeholder="placeholder"
      :value="modelValue"
      :maxlength="maxLength"
      @input="$emit('update:modelValue', $event.target.value)"
      @change="$emit('change', $event)"
    />
    <span
      v-if="remainingCharsText"
      class="text-xs text-foreground-light ml-0.5"
    >
      {{ remainingCharsText }}
    </span>
  </div>
</template>


<script lang="ts">
import { defineComponent, onMounted, PropType, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

const commonDefaultInputProps = {
  modelValue: {
    type: [String, Number] as PropType<string | number>,
    default: '',
  },
  placeholder: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    default: 'text',
  },
  error: {
    type: Boolean,
    default: false,
  },
  maxLength: {
    type: [Number, String],
    default: undefined,
  },
  autocomplete: {
    type: String,
    default: 'on',
  },
  accept: {
    type: String,
    default: '',
  },
};

export default defineComponent({
  props: {
    ...commonDefaultInputProps,
  },
  emits: ['update:modelValue', 'change'],
  setup(props, { emit }) {
    const { t } = useI18n();

    const inputElement = ref<HTMLInputElement | null>(null);
    const textareaElement = ref<HTMLTextAreaElement | null>(null);

    const remainingCharsText = ref<string | null>(null);

    watch(() => props.modelValue, (newValue) => {
      if (typeof props.maxLength === 'number') {
        const remaining = props.maxLength - newValue.toString().length;
        remainingCharsText.value = t('components.defaultInput.charsLeft', { chars: remaining });
      }
    });

    function focus() {
      if (props.type !== 'textarea' && inputElement.value) {
        inputElement.value.focus();
      } else if (textareaElement.value) {
        textareaElement.value.focus();
      }
    }

    onMounted(() => {
      if (typeof props.maxLength === 'number') {
        const remaining = props.maxLength - props.modelValue.toString().length;
        remainingCharsText.value = t('components.defaultInput.charsLeft', { chars: remaining });
      }
    });

    return {
      inputElement,
      textareaElement,
      focus,
      remainingCharsText
    };
  },
});

export { commonDefaultInputProps };

</script>
