const upperSnakeCaseToCamelCase = (input: string): string => input
  .toLowerCase()
  .split('_')
  .map((part, index) => {
    if (index === 0) {
      return part;
    }
    return part.charAt(0).toUpperCase() + part.slice(1);
  })
  .join('');

const camelCaseToUpperSnakeCase = (input: string): string => input
  .split('')
  .map((char) => {
    if (char === char.toUpperCase()) {
      return `_${char}`;
    }
    return char;
  })
  .join('')
  .toUpperCase();

const formatBytes = (bytes: number): string => {
  if (bytes < 1024) {
    return `${bytes} Bytes`;
  }
  if (bytes < 1048576) {
    return `${Math.floor(bytes / 1024)} KB`;
  }
  if (bytes < 1073741824) {
    return `${(bytes / 1048576).toFixed(1)} MB`;
  }
  return `${(bytes / 1073741824).toFixed(1)} GB`;

};

export {
  upperSnakeCaseToCamelCase,
  camelCaseToUpperSnakeCase,
  formatBytes,
};
