<template>
  <HorizontalStack
    @mouseenter="handleMouse(true)"
    @mouseleave="handleMouse(false)"
    class="justify-items-stretch w-full border-t border-border"
    :spacing="null"
    alignment="middle"
    v-if="bottomBarVisible"
    ref="bottomNavigationBar"
  >
    <BottomNavigationBarItem
      v-if="imagesVisible"
      :title="$t('screens.addin.bottomNavigation.images')"
      icon="/icons/image.svg"
      to="/addin/content/imagepool"
      class="buttom_navigation_bar_item"
      :collapsed="collapsed && !mouseIsHovering"
    />
    <BottomNavigationBarItem
      v-if="powerPointVisible"
      :title="$t('screens.addin.bottomNavigation.powerPoint')"
      icon="/icons/presentation.svg"
      to="/addin/content/powerpoint"
      class="buttom_navigation_bar_item"
      :collapsed="collapsed && !mouseIsHovering"
    />
    <BottomNavigationBarItem
      v-if="iconsVisible"
      :title="$t('screens.addin.bottomNavigation.icons')"
      icon="/icons/icon.svg"
      to="/addin/content/iconpool"
      class="buttom_navigation_bar_item"
      :collapsed="collapsed && !mouseIsHovering"
    />
  </HorizontalStack>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue';
import HorizontalStack from '@/components/layout/HorizontalStack.vue';
import BottomNavigationBarItem from '@/components/controls/BottomNavigationBarItem.vue';
import { useStore } from 'vuex';
import { AddinSettings } from '@/types/OrganizationSettings';

export default defineComponent({
  components: { BottomNavigationBarItem, HorizontalStack },
  props: {
    collapsed: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const store = useStore();
    const bottomNavigationBar = ref<InstanceType<typeof HorizontalStack> | null>(null);
    const mouseIsHovering = ref(false);

    const addinSettings = computed(() => store.getters.addinSettings as AddinSettings | null);

    const powerPointVisible = computed(() => {
      if (!addinSettings) {
        return false;
      }
      return !addinSettings.value?.presentationsHidden || !addinSettings.value?.slidesHidden;
    });

    const imagesVisible = computed(() => {
      if (!addinSettings) {
        return false;
      }
      return !addinSettings.value?.imagesHidden || !addinSettings.value?.onlineImagesHidden;
    });

    const iconsVisible = computed(() => {
      if (!addinSettings) {
        return false;
      }
      return !addinSettings.value?.iconsHidden || !addinSettings.value?.animatedIconsHidden;
    });

    const handleBarPosition = () => {
      if (!bottomNavigationBar.value) return;
      if (props.collapsed && !mouseIsHovering.value) {
        bottomNavigationBar.value.$el.style.bottom = '-25px';
      } else {
        bottomNavigationBar.value.$el.style.bottom = '0';
      }
    }

    const handleMouse = (isHovering: boolean) => {
      mouseIsHovering.value = isHovering;
      handleBarPosition();
    };

    watch(() => props.collapsed, () => {
      handleBarPosition();
    });

    const bottomBarVisible = computed(() => {
      if (!addinSettings) {
        return false;
      }
      const visibleCount = [powerPointVisible.value, imagesVisible.value, iconsVisible.value].filter(Boolean).length;
      return visibleCount >= 2;
    });

    return {
      bottomBarVisible,
      powerPointVisible,
      imagesVisible,
      iconsVisible,
      bottomNavigationBar,
      handleMouse,
      mouseIsHovering,
    };
  },
});
</script>

<style scoped>
.buttom_navigation_bar_item {
  @apply flex-1 overflow-hidden;
}
</style>
