<template>
  <div>
    <!-- Leerer Inhalt oder eine Nachricht wie "Seite im Aufbau" -->
    <p>404: Das könnte heißen dass 404 Affen versuchen diese Seite gerade aufzubauen.
      Oder es gibt sie einfach nicht...</p>
    <p></p>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';

export default defineComponent({
  setup() {
    const route = ref(useRoute());

    return { route };
  },
});
</script>
