import { captcha } from '@/config';

declare global {
  interface Window {
    onRecaptchaLoad: () => void;
  }
}

export const loadRecaptcha = async (
  containerName: string,
  callback: (token: string) => void,
  expiredCallback: () => void,
  errorCallback: () => void,
): Promise<number> => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js?onload=onRecaptchaLoad&render=explicit';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    window.onRecaptchaLoad = () => {
      const id = grecaptcha.render(containerName, {
        sitekey: captcha.siteKey,
        action: 'verify',
        size: 'compact',
        callback: callback,
        'expired-callback': expiredCallback,
        'error-callback': errorCallback,
      });
      resolve(id);
    };
  });
};

export {};
