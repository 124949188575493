import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "text-error" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CodeInput = _resolveComponent("CodeInput")!
  const _component_labeled_default_input = _resolveComponent("labeled-default-input")!
  const _component_default_input = _resolveComponent("default-input")!
  const _component_default_button = _resolveComponent("default-button")!
  const _component_WrappingFlow = _resolveComponent("WrappingFlow")!
  const _component_VerticalStack = _resolveComponent("VerticalStack")!
  const _component_card_element = _resolveComponent("card-element")!
  const _component_OverlayBackground = _resolveComponent("OverlayBackground")!

  return (_openBlock(), _createBlock(_component_OverlayBackground, { class: "z-30" }, {
    default: _withCtx(() => [
      _createVNode(_component_card_element, { class: "sm:w-5/6 md:w-1/2 lg:w-1/3 w-5/6" }, {
        default: _withCtx(() => [
          _createVNode(_component_VerticalStack, null, {
            default: _withCtx(() => [
              (_ctx.alert.title)
                ? (_openBlock(), _createElementBlock("h2", _hoisted_1, _toDisplayString(_ctx.alert.title), 1 /* TEXT */))
                : _createCommentVNode("v-if", true),
              (_ctx.alert.message)
                ? (_openBlock(), _createElementBlock("p", {
                    key: 1,
                    innerHTML: _ctx.alert.message
                  }, null, 8 /* PROPS */, _hoisted_2))
                : _createCommentVNode("v-if", true),
              (_ctx.alert.type === 'code')
                ? (_openBlock(), _createBlock(_component_CodeInput, {
                    key: 2,
                    class: "w-[270px] mx-auto",
                    modelValue: _ctx.input,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.input) = $event)),
                    ref: "inputElement"
                  }, null, 8 /* PROPS */, ["modelValue"]))
                : _createCommentVNode("v-if", true),
              (_ctx.alert.type === 'text' && _ctx.alert.textLabel)
                ? (_openBlock(), _createBlock(_component_labeled_default_input, {
                    key: 3,
                    class: "w-full",
                    modelValue: _ctx.input,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.input) = $event)),
                    type: _ctx.alert.inputType,
                    label: _ctx.alert.textLabel,
                    ref: "inputElement"
                  }, null, 8 /* PROPS */, ["modelValue", "type", "label"]))
                : _createCommentVNode("v-if", true),
              (_ctx.alert.type === 'text' && !_ctx.alert.textLabel)
                ? (_openBlock(), _createBlock(_component_default_input, {
                    key: 4,
                    class: "w-full",
                    type: _ctx.alert.inputType,
                    modelValue: _ctx.input,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.input) = $event)),
                    ref: "inputElement"
                  }, null, 8 /* PROPS */, ["type", "modelValue"]))
                : _createCommentVNode("v-if", true),
              _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.errorMessage), 1 /* TEXT */),
              _createVNode(_component_WrappingFlow, {
                gap: "small",
                class: "justify-center sm:justify-start"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.alert.buttons, (button, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index,
                      class: "w-full sm:w-auto"
                    }, [
                      _createVNode(_component_default_button, {
                        label: button.title,
                        class: "w-full",
                        type: button.type,
                        "auto-loading-state": button.autoLoadingState,
                        onClick: () => _ctx.buttonIndexClicked(index)
                      }, null, 8 /* PROPS */, ["label", "type", "auto-loading-state", "onClick"])
                    ]))
                  }), 128 /* KEYED_FRAGMENT */))
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}