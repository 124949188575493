<template>
  <div class="flex flex-wrap"
    :class="[
      gap === 'tiny' ? 'gap-0.5' : '',
      gap === 'small' ? 'gap-2' : '',
      gap === 'medium' ? 'gap-6' : '',
      gap === 'large' ? 'gap-12' : '',
      alignment === 'top' ? 'items-start' : '',
      alignment === 'middle' ? 'items-center' : '',
      alignment === 'bottom' ? 'items-end' : '',
      alignment === 'baseline' ? 'items-baseline' : '',
      alignment === 'stretch' ? 'items-stretch' : '',
    ]"
  >
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

type Gap = 'tiny' | 'small' | 'medium' | 'large';
type Alignment = 'top' | 'middle' | 'bottom' | 'baseline' | 'stretch';

export default defineComponent({
  props: {
    gap : {
      type: String as PropType<Gap>,
      default: 'medium',
    },
    alignment: {
      type: String as PropType<Alignment>,
      default: 'top',
    },
  },
  setup() {
    return {};
  },
});

export { Gap, Alignment };
</script>
