import { App } from 'vue';
import { Store } from 'vuex';

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $userAllowed: (permissionName: string) => boolean;
  }
}

export default {
  install(app: App, options: { store: Store<any> }) {
    app.config.globalProperties.$userAllowed = (permissionName: string): boolean => {
      const permissions = options.store.state.user?.permissions ?? [];
      return permissions.includes(permissionName);
    };
  },
};
