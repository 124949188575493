<template>
  <HorizontalStack alignment="middle" class="w-full">
    <SimpleDivider class="flex-grow" />
    <span class="text-foreground-light"><slot /></span>
    <SimpleDivider class="flex-grow" />
  </HorizontalStack>
</template>

<script setup lang="ts">
import HorizontalStack from '@/components/layout/HorizontalStack.vue';
import SimpleDivider from '@/components/viewelements/SimpleDivider.vue';

</script>
