import { createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-foreground-light" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["HorizontalStack"], {
    alignment: "middle",
    class: "w-full"
  }, {
    default: _withCtx(() => [
      _createVNode($setup["SimpleDivider"], { class: "flex-grow" }),
      _createElementVNode("span", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default")
      ]),
      _createVNode($setup["SimpleDivider"], { class: "flex-grow" })
    ]),
    _: 3 /* FORWARDED */
  }))
}