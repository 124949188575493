import { RouteRecordRaw } from 'vue-router';
import profileRoutes from '@/router/profile-routes';
import MainContainer from '@/views/admin/MainContainer.vue';
import fileRoutes from '@/router/file-routes';
import FilesView from '@/views/admin/files/FilesView.vue';
import MenuDetailView from '@/components/layout/MenuDetailView.vue';
import organizationRoutes from '@/router/organization-routes';

const routes: Array<RouteRecordRaw> = [
  ...profileRoutes,
  ...organizationRoutes,
  ...fileRoutes
];

export default routes;
