import type { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: 'licenses',
    name: 'su-licenses',
    component: () => import('@/views/superuser/licenses/LicensesView.vue'),
    children: [
      {
        path: '',
        name: 'su-licenses-list',
        component: () => import('@/views/superuser/licenses/LicensesListView.vue'),
      },
      {
        path: ':reference',
        name: 'su-license-details-view',
        component: () => import('@/views/superuser/licenses/LicenseDetailsView.vue'),
      }
    ]
  }
];

export default routes;
