const inOfficeAddin = window.location.pathname.startsWith('/addin');
let isPowerPoint = false;

const setPowerPoint = (value: boolean) => {
  isPowerPoint = value;
}

export {
  inOfficeAddin,
  isPowerPoint,
  setPowerPoint,
};
