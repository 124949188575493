const api = {
  v1: 'https://api.slideroom.de/v1',
  basicUser: '',
  basicPassword: '',
};

const azure = {
  clientId: '7160553f-3a95-4c95-9e7a-0d662cebb378',
  redirectUris: {
    oAuth: 'https://app.slideroom.de/oauth2/azure',
    adminConsent: 'https://app.slideroom.de/azure/admin-consent',
  } ,
};

const ui = {
  flyOutAnimationResetTime: 1000,
};

const system = {
  doAxiosDelay: false,
  environment: 'prod',
  appInsightsConnectionString: 'InstrumentationKey=126525b5-77b7-46d9-8d5b-ca49d9e25422;IngestionEndpoint=https://germanywestcentral-1.in.applicationinsights.azure.com/;LiveEndpoint=https://germanywestcentral.livediagnostics.monitor.azure.com/;ApplicationId=a06d47b1-6e0b-4fdd-b2c4-454241716134'
};

const captcha = {
  siteKey: '6Lc2JhQqAAAAAFK99r5ZBNyOKXc1ajt7zJhxZwr7',
};

export {
  api,
  azure,
  ui,
  system,
  captcha,
};
