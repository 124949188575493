import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-03fbb954"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = {
  key: 2,
  class: "text-foreground-light text-sm"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HorizontalStack = _resolveComponent("HorizontalStack")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: _ctx.mainPath,
    class: "text-foreground"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_HorizontalStack, { alignment: "middle" }, {
        default: _withCtx(() => [
          (_ctx.logoUrl && !_ctx.imageLoadError)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: _ctx.logoUrl,
                class: _normalizeClass(_ctx.logoClass),
                onError: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onImageLoadError && _ctx.onImageLoadError(...args))),
                alt: "Logo"
              }, null, 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_1))
            : (_openBlock(), _createElementBlock("img", {
                key: 1,
                src: "/logo-without-claim.png",
                class: _normalizeClass(_ctx.logoClass),
                onError: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onImageLoadError && _ctx.onImageLoadError(...args))),
                alt: "Logo"
              }, null, 34 /* CLASS, NEED_HYDRATION */)),
          (!_ctx.inOfficeAddin)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, " Admin "))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["to"]))
}