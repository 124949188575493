import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { system } from '@/config';

let appInsights: ApplicationInsights | null = null;

const createAppInsights = (): ApplicationInsights => {
  appInsights = new ApplicationInsights({
    config: {
      connectionString: system.appInsightsConnectionString,
      enableAutoRouteTracking: true,
      disableCookiesUsage: true,
    }
  });
  return appInsights;
};

const removeAppInsights = (): void => { appInsights = null; };

export {
  appInsights,
  createAppInsights,
  removeAppInsights,
};
