import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, withModifiers as _withModifiers, createElementVNode as _createElementVNode, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_VerticalStack = _resolveComponent("VerticalStack")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.$slots.submenu && _ctx.to && _ctx.hasPermission && _ctx.exact)
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 0,
          to: _ctx.to,
          class: _normalizeClass(_ctx.classes),
          "exact-active-class": "bg-background-dark",
          exact: ""
        }, {
          default: _withCtx(() => [
            (_ctx.hinted)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(_ctx.hintDotClasses)
                }, null, 2 /* CLASS */))
              : _createCommentVNode("v-if", true),
            (_ctx.hinted)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: _normalizeClass([_ctx.hintDotClasses, 'animate-ping'])
                }, null, 2 /* CLASS */))
              : _createCommentVNode("v-if", true),
            (_ctx.icon)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 2,
                  src: _ctx.icon,
                  alt: "User",
                  class: _normalizeClass(["mr-2 last:mr-0", _ctx.padded ? 'w-4 h-4' : 'w-6 h-6'])
                }, null, 10 /* CLASS, PROPS */, _hoisted_1))
              : _createCommentVNode("v-if", true),
            (_ctx.label)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 3,
                  class: _normalizeClass(_ctx.labelSpanClasses)
                }, _toDisplayString(_ctx.label), 3 /* TEXT, CLASS */))
              : _createCommentVNode("v-if", true),
            _renderSlot(_ctx.$slots, "default")
          ]),
          _: 3 /* FORWARDED */
        }, 8 /* PROPS */, ["to", "class"]))
      : _createCommentVNode("v-if", true),
    (!_ctx.$slots.submenu && _ctx.to && _ctx.hasPermission && !_ctx.exact)
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 1,
          to: _ctx.to,
          class: _normalizeClass(_ctx.classes),
          "active-class": "bg-background-dark"
        }, {
          default: _withCtx(() => [
            (_ctx.hinted)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(_ctx.hintDotClasses)
                }, null, 2 /* CLASS */))
              : _createCommentVNode("v-if", true),
            (_ctx.hinted)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: _normalizeClass([_ctx.hintDotClasses, 'animate-ping'])
                }, null, 2 /* CLASS */))
              : _createCommentVNode("v-if", true),
            (_ctx.icon)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 2,
                  src: _ctx.icon,
                  alt: "User",
                  class: _normalizeClass(["mr-2 last:mr-0", _ctx.padded ? 'w-4 h-4' : 'w-6 h-6'])
                }, null, 10 /* CLASS, PROPS */, _hoisted_2))
              : _createCommentVNode("v-if", true),
            (_ctx.label)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 3,
                  class: _normalizeClass(_ctx.labelSpanClasses)
                }, _toDisplayString(_ctx.label), 3 /* TEXT, CLASS */))
              : _createCommentVNode("v-if", true),
            _renderSlot(_ctx.$slots, "default")
          ]),
          _: 3 /* FORWARDED */
        }, 8 /* PROPS */, ["to", "class"]))
      : _createCommentVNode("v-if", true),
    (!_ctx.$slots.submenu && !_ctx.to && _ctx.hasPermission)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: _normalizeClass([
      _ctx.classes,
      _ctx.active ? 'bg-background-dark' : '',
      'cursor-pointer'
    ]),
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)), ["stop"]))
        }, [
          (_ctx.hinted)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(_ctx.hintDotClasses)
              }, null, 2 /* CLASS */))
            : _createCommentVNode("v-if", true),
          (_ctx.hinted)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass([_ctx.hintDotClasses, 'animate-ping'])
              }, null, 2 /* CLASS */))
            : _createCommentVNode("v-if", true),
          (_ctx.icon)
            ? (_openBlock(), _createElementBlock("img", {
                key: 2,
                src: _ctx.icon,
                alt: "User",
                class: _normalizeClass(["mr-2 last:mr-0", _ctx.padded ? 'w-4 h-4' : 'w-6 h-6'])
              }, null, 10 /* CLASS, PROPS */, _hoisted_3))
            : _createCommentVNode("v-if", true),
          (_ctx.label)
            ? (_openBlock(), _createElementBlock("span", {
                key: 3,
                class: _normalizeClass(_ctx.labelSpanClasses)
              }, _toDisplayString(_ctx.label), 3 /* TEXT, CLASS */))
            : _createCommentVNode("v-if", true),
          _renderSlot(_ctx.$slots, "default")
        ], 2 /* CLASS */))
      : _createCommentVNode("v-if", true),
    (_ctx.hasPermission && _ctx.$slots.submenu && _ctx.hasPermission)
      ? (_openBlock(), _createElementBlock("div", {
          key: 3,
          class: "relative",
          ref: "menuHolderRef",
          onMouseleave: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.scheduleCloseMenu && _ctx.scheduleCloseMenu(...args))),
          onMouseenter: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.cancelCloseMenu && _ctx.cancelCloseMenu(...args)))
        }, [
          _createElementVNode("div", {
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleSubmenu && _ctx.toggleSubmenu(...args))),
            ref: "menuMainRef",
            class: _normalizeClass(`${_ctx.classes} cursor-pointer`)
          }, [
            (_ctx.hinted)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(_ctx.hintDotClasses)
                }, null, 2 /* CLASS */))
              : _createCommentVNode("v-if", true),
            (_ctx.hinted)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: _normalizeClass([_ctx.hintDotClasses, 'animate-ping'])
                }, null, 2 /* CLASS */))
              : _createCommentVNode("v-if", true),
            (_ctx.icon)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 2,
                  src: _ctx.icon,
                  alt: "User",
                  class: _normalizeClass(["mr-2 last:mr-0", _ctx.padded ? 'w-4 h-4' : 'w-6 h-6'])
                }, null, 10 /* CLASS, PROPS */, _hoisted_4))
              : _createCommentVNode("v-if", true),
            (_ctx.label)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 3,
                  class: _normalizeClass(_ctx.labelSpanClasses)
                }, _toDisplayString(_ctx.label), 3 /* TEXT, CLASS */))
              : _createCommentVNode("v-if", true),
            _renderSlot(_ctx.$slots, "default")
          ], 2 /* CLASS */),
          (_ctx.isSubmenuOpen)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                ref: "menuRef",
                class: _normalizeClass(["border border-border mx-auto p-4 bg-background rounded-lg shadow-xl z-50", [
        'absolute mt-2',
        _ctx.shouldAlignRight ? 'origin-top-right right-0' : 'origin-top-left left-0'
      ]])
              }, [
                _createVNode(_component_VerticalStack, { spacing: null }, {
                  default: _withCtx(() => [
                    _renderSlot(_ctx.$slots, "submenu")
                  ]),
                  _: 3 /* FORWARDED */
                })
              ], 2 /* CLASS */))
            : _createCommentVNode("v-if", true)
        ], 544 /* NEED_HYDRATION, NEED_PATCH */))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}