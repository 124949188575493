<template>
  <div
    v-if="shouldDisplayMessage"
    :class="`text-sm p-2 rounded-lg border w-full mt-4 ${messageTypeClass}`">
    {{ message }}
  </div>
</template>

<script lang="ts">
import {
  PropType,
  defineComponent,
  ref,
  watch,
  computed,
} from 'vue';

export default defineComponent({
  name: 'MessageComponent',
  props: {
    message: {
      type: String as PropType<string | null>,
      default: null,
    },
    type: {
      type: String as PropType<'success' | 'error'>,
      default: 'success',
    },
    autoRemove: {
      type: [Boolean, String] as PropType<boolean | string>,
      default: false,
    },
  },
  setup(props, { emit }) {
    const isVisible = ref(false);
    const shouldDisplayMessage = computed(() => isVisible.value);

    let removeTimer: ReturnType<typeof setTimeout> | null = null;

    function removeMessage() {
      isVisible.value = false;
      emit('remove');
    }

    function resetRemoveTimer(delay: number) {
      if (removeTimer !== null) {
        clearTimeout(removeTimer);
      }
      removeTimer = setTimeout(removeMessage, delay);
    }

    watch(() => props.message, (newMessage) => {
      if (newMessage && newMessage.trim().length > 0) {
        isVisible.value = true;
        if (props.autoRemove) {
          const delay = typeof props.autoRemove === 'string' ? parseInt(props.autoRemove, 10) * 1000 : 4000;
          resetRemoveTimer(delay);
        }
      }
    }, { immediate: true });

    const messageTypeClass = computed(() => {
      switch (props.type) {
        case 'success':
          return 'bg-success-ultraLight border-success text-success';
        case 'error':
        default:
          return 'bg-error-ultraLight border-error text-error';
      }
    });

    return { shouldDisplayMessage, messageTypeClass };
  },
});

</script>
