import { ActionContext } from 'vuex';
import loadingContext from '@/types/LoadingContext';
import LoadingContext from '@/types/LoadingContext';

interface LoadingState {
  counters: Record<string, number>;
}

const state: LoadingState = {
  counters: {}
};

const getters = {
  isLoading: (state: LoadingState) => (context?: loadingContext): boolean => {
    if (context === 'global') {
      return Object.values(state.counters).some(count => count > 0);
    }
    if (context) {
      return state.counters[context] > 0;
    }
    return Object.values(state.counters).some(count => count > 0);
  }
};

const mutations = {
  START_LOADING(state: LoadingState, context: LoadingContext = 'global') {
    if (!state.counters[context]) {
      state.counters[context] = 0;
    }
    state.counters[context] += 1;
  },
  STOP_LOADING(state: LoadingState, context: LoadingContext = 'global') {
    if (state.counters[context] && state.counters[context] > 0) {
      state.counters[context] -= 1;
    }
  },
};

type LoadingStateActionContext = ActionContext<LoadingState, any>;

const actions = {
  startLoading({ commit }: LoadingStateActionContext, context?: LoadingContext) {
    commit('START_LOADING', context || 'global');
    console.log('loading start', context);
  },
  stopLoading({ commit }: LoadingStateActionContext, context?: LoadingContext) {
    commit('STOP_LOADING', context || 'global');
    console.log('loading stop', context);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
