<template>
  <span
    v-if="system.environment !== 'prod'"
    class="text-xs text-foreground-light"
    :class="{ 'clickable': clickable }"
    @click.stop="showChangelog"
  >
    {{ `${frontendVersion} | ${backendVersion}` }}
  </span>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import useModal from '@/composables/use-modal';
import { system } from '@/config';

export default defineComponent({
  computed: {
    system() {
      return system
    }
  },
  props: {
    clickable: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const store = useStore();
    const { showModal } = useModal();

    const backendVersion = computed(() => store.getters.backendVersionInfo);
    const frontendVersion = ref('');

    onMounted(() => {
      fetch('/version.info')
        .then(response => response.text())
        .then(data => {
          const lines = data.split('\n');
          frontendVersion.value = lines.length > 1 ? `${lines[0]} (${lines[1]})` : '- (-)';
        });
    });

    const showChangelog = () => {
      if (!props.clickable) return;
      showModal({
        title: "Change Log",
        content: 'views/ChangelogView'
      });
    }

    return {
      backendVersion,
      frontendVersion,
      showChangelog,
    };
  },
});
</script>
