export function formatLongDate(date: Date): string {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: undefined,
  };

  return new Intl.DateTimeFormat(undefined, options).format(date).replace(',', '');
}

export function formatDate(date: Date): string {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };

  return new Intl.DateTimeFormat(undefined, options).format(date);
}

export function isIsoDate(str: string): boolean {
  return /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?(?:Z|[+-]\d{2}:\d{2})?$/.test(str);
}

export function convertIsoStringsToDate(obj: any): any {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    if (typeof value === 'string' && isIsoDate(value)) {
      // eslint-disable-next-line no-param-reassign
      obj[key] = new Date(value);
    } else if (typeof value === 'object') {
      convertIsoStringsToDate(value);
    }
  });

  return obj;
}
