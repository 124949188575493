import { RouteRecordRaw } from 'vue-router';
import ProfileView from '@/views/admin/profile/ProfileView.vue';
import ProfileDataView from '@/views/admin/profile/ProfileEditDataView.vue';
import ProfilePasswordView from '@/views/admin/profile/ProfileAuthenticationView.vue';
import EmptyView from '@/components/PlaceholderView.vue';
import FilesView from '@/views/admin/files/FilesView.vue';
import ManageContentVue from '@/views/admin/files/ManageContentView.vue';

const contentChildren: (area: string, defaultRedirect: string) => Array<RouteRecordRaw> = (area, defaultRedirect) => [
  {
    path: '',
    name: 'content-manager-base-' + area,
    redirect: `/admin/files/content/${area}/${defaultRedirect}`,
  },
  {
    path: ':type',
    name: 'content-manager-categories-' + area,
    component: () => import('@/views/admin/files/ManageContentCategoryDetailView.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: ':type/:category',
    name: 'content-manager-category-detail-' + area,
    component: () => import('@/views/admin/files/ManageContentCategoryDetailView.vue'),
    meta: {
      requiresAuth: true,
    },
  },
];

const routes: Array<RouteRecordRaw> = [
  {
    path: 'files',
    name: 'files',
    component: FilesView,
    redirect: '/admin/files/content/imagepool/images',
    meta: {
      requiresAuth: true,
      titleKey: 'screens.files.title',
    },
    children: [
      {
        path: 'content/imagepool',
        name: 'content-manager-imagepool',
        component: () => import('@/views/admin/files/ManageContentView.vue'),
        meta: {
          requiresAuth: true,
        },
        children: contentChildren('imagepool', 'images'),
      },
      {
        path: 'content/powerpoint',
        name: 'content-manager-powerpoint',
        component: () => import('@/views/admin/files/ManageContentView.vue'),
        meta: {
          requiresAuth: true,
        },
        children: contentChildren('powerpoint', 'presentations'),
      },
      {
        path: 'content/iconpool',
        name: 'content-manager-iconpool',
        component: () => import('@/views/admin/files/ManageContentView.vue'),
        meta: {
          requiresAuth: true,
        },
        children: contentChildren('iconpool', 'icons'),
      },
      {
        path: 'tags/:slug',
        name: 'tags',
        component: () => import('@/views/admin/files/TagView.vue'),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
];

export default routes;
