<template>
  <div>
    <p>
      Dies ist ein Platzhalter... Normalerweise wäre hier
      <span class="font-bold">{{ route.name }}</span>
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';

export default defineComponent({
  setup() {
    const route = ref(useRoute());

    return { route };
  },
});
</script>
