import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: ':slug',
    name: 'organization',
    component: () => import('@/views/admin/organization/OrganizationView.vue'),
    meta: {
      requiresAuth: true,
      titleKey: 'screens.organization.title',
      permissions: ['ORGANIZATION_EDIT'],
    },
    children: [
      {
        path: '',
        name: 'organization',
        component: () => import('@/views/admin/organization/OrganizationGeneralView.vue'),
        meta: {
          requiresAuth: true,
          permissions: ['ORGANIZATION_EDIT'],
        },
      },
      {
        path: 'members',
        name: 'organization-members',
        component: () => import('@/views/admin/organization/members/OrganizationMembersView.vue'),
        meta: {
          requiresAuth: true,
          permissions: ['ORGANIZATION_USERS_READ'],
        },
        children: [
          {
            path: '',
            component: () => import('@/views/admin/organization/members/OrganizationMembersListView.vue'),
            meta: {
              requiresAuth: true,
              permissions: ['USER_READ_OTHERS'],
            },
          },
          {
            path: ':reference',
            name: 'organization-member',
            component: () => import('@/views/admin/organization/members/OrganizationMemberDetailsView.vue'),
            meta: {
              requiresAuth: true,
              permissions: ['USER_READ_OTHERS'],
            },
          },
        ],
      },
      {
        path: 'licenses',
        name: 'organization-licenses',
        component: () => import('@/views/admin/organization/licenses/OrganizationManageLicenseView.vue'),
        meta: {
          requiresAuth: true,
          permissions: ['LICENSE_READ'],
        },
      },
      {
        path: 'addinsettings',
        name: 'organization-addinsettings',
        component: () => import('@/views/admin/organization/settings/OrganizationManageAddInSettingsView.vue'),
        meta: {
          requiresAuth: true,
          permissions: ['ORGANIZATION_READ'],
        },
      },
      {
        path: 'azure-sync',
        name: 'organization-azure-sync',
        component: () => import('@/views/admin/organization/OrganizationAzureSyncView.vue'),
        meta: {
          requiresAuth: true,
          permissions: ['ORGANIZATION_USERS_READ'],
        },
      },
    ],
  },
];

export default routes;
