<template>
  <VerticalStack class="w-full p-8" alignment="center">
    <MainLogoComponent />
    <h2>{{ $t('screens.login.licenseError.title') }}</h2>
    <span class="text-center max-w-72 w-full">{{ $t('screens.login.licenseError.text') }}</span>
    <default-button
      :label="$t('screens.login.licenseError.retry')"
      class="max-w-72 w-full"
      @click="retry"
    />
    <default-button
      type="secondary"
      :label="$t('screens.login.licenseError.backToLogin')"
      class="max-w-72 w-full"
      @click="backToLogin"
    />
  </VerticalStack>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import VerticalStack from '@/components/layout/VerticalStack.vue';
import MainLogoComponent from '@/components/viewelements/main/MainLogoComponent.vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  components: {MainLogoComponent, VerticalStack},
  setup() {
    const router = useRouter();

    const retry = () => {
      window.location.href = '/addin';
      window.location.reload();
    }

    const backToLogin = async () => {

      // TODO: This needs to be fixed, the navigation should happen to the addin page and ignore the microsoft login.
      await router.push('/login');
    }

    return {
      retry,
      backToLogin,
    };
  },
});
</script>
