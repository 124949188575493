<template>
  <default-button
    @click="logoutClicked"
    :type="buttonType"
    auto-loading-state="global"
    class="px-4"
  >
    {{ $t('components.logout.button') }}
  </default-button>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue';
import MeService from '@/services/MeService';
import { useStore } from 'vuex';
import { ButtonType } from '@/components/controls/DefaultButton.vue';

export default defineComponent({
  props: {
    buttonType: {
      type: String as PropType<ButtonType>,
      required: false,
      default: 'link'
    },
  },
  setup() {
    const store = useStore();

    const logoutClicked = async () => {
      const shouldReload = await MeService.logout();
      if (shouldReload) {
        window.location.reload();
      }
    };

    return { logoutClicked };
  },
});
</script>
