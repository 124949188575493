import axios from 'axios';
import LoginResult from '@/types/LoginResult';
import MSALCodeVerifierService from '@/services/MSALCodeVerifierService';
import { api, azure } from '../config';
import { Router } from 'vue-router';
import { inOfficeAddin } from '@/office-checker';
import { isAxiosErrorWithCode } from '@/utils/axios-error';
import MeService from '@/services/MeService';
import store from '@/store/store';
import { LocalSettingsService } from '@/services/LocalSettingsService';

class AuthService {
  static async login(email: string, password: string, stayLoggedIn: boolean, router: Router): Promise<void> {
    const result = (await axios.post<LoginResult>(`${api.v1}/auth/signin`, { email, password, stayLoggedIn })).data;
    if (result.result === 'SUCCESS') {
      await store.dispatch('loadUser');
      await router.push('/');
    } else {
      await router.push({ name: 'twoFA', params: { type: result.twoFAType }, query: { email: email } });
    }
  }

  static async twoFA(token: string, email: string, router: Router): Promise<void> {
    await axios.post(`${api.v1}/auth/2fa`, { token, email });
    await LocalSettingsService.setLoginStateIntention('selfLoggedIn')
    await store.dispatch('loadUser');
    await router.push('/');
  }

  static async register(
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    captchaToken: string,
    router: Router,
  ): Promise<void> {
    await axios.post(`${api.v1}/auth/signup`, {
      email,
      password,
      firstName,
      lastName,
      captchaToken,
    });
    await LocalSettingsService.setLoginStateIntention('selfLoggedIn')
    await store.dispatch('loadUser');
    await router.push('/');
  }

  static async refresh(): Promise<void> {
    await axios.post(`${api.v1}/auth/refresh`);
  }

  static async getPasswordRules(): Promise<string> {
    const { data } = await axios.get(`${api.v1}/auth/passwordrule`);
    return data;
  }

  static checkPassword(password: string, ruleRegex: string): boolean {
    return new RegExp(ruleRegex).test(password);
  }

  static async requestForgotPasswordProcess(email: string): Promise<void> {
    await axios.post(`${api.v1}/auth/requestpasswordreset`, { email });
  }

  static async verifyPasswordReset(token: string, password: string): Promise<void> {
    await axios.post(`${api.v1}/auth/verifypasswordreset`, { token, newPassword: password });
  }

  private static setMSALVerifier(verifier: string): void {
    sessionStorage.setItem('msalCodeVerifier', verifier);
    document.cookie = `msalCodeVerifier=${verifier}`;
  }

  private static getMSALVerifier(): string {
    return sessionStorage.getItem('msalCodeVerifier') ?? '';
  }

  static deleteMSALVerifier(): void {
    sessionStorage.removeItem('msalCodeVerifier');
  }

  /**
   * Tries to authorize the user by the token office provides. The authorization and token handling
   * is done by the backend, as usual.
   */
  static async checkOfficeAndPreauth(router: Router, ignoreErrors: boolean): Promise<void> {
    if (!inOfficeAddin || (await LocalSettingsService.getLoginStateIntention())){
      return;
    }
    try {
      console.log("Trying to get a token from Office.");
      const token = await Office.auth.getAccessToken({ allowConsentPrompt: true, allowSignInPrompt: true });
      console.log('Got token successful.');
      if (token) {
        await axios.post(`${api.v1}/auth/officeaddin/signin`, { token });
      }
    } catch(error) {
      console.error('Error while trying to authorize with office:', error)
      if (inOfficeAddin && isAxiosErrorWithCode(error, 403)) {
        await router.replace('/no/valid/license');
      } else if (!ignoreErrors) {
        throw error;
      }
    }
  }

  static async startAuthenticationWithPowerPoint(router: Router): Promise<void> {
    try {
      await MeService.logout();
    } catch {
      // ignore
    }
    await LocalSettingsService.setLoginStateIntention(null);
    await this.checkOfficeAndPreauth(router, false);
    await store.dispatch('loadUser')
    await router.replace('/addin')
  }

  static async passAzureAuth2Code(code: string, stayLoggedIn: boolean): Promise<void> {
    const verifier = this.getMSALVerifier();
    this.deleteMSALVerifier();
    await axios.post(`${api.v1}/auth/microsoft/callback`, { code, verifier, stayLoggedIn });
  }

  static async startAuthenticationWithMicrosoft(): Promise<void> {
    this.deleteMSALVerifier();

    const [verifier, challenge] = await MSALCodeVerifierService.getCodeVerifierAndChallenge();

    this.setMSALVerifier(verifier);

    const clientId = azure.clientId;
    const authority = 'https://login.microsoftonline.com/common';
    const redirectUri = encodeURIComponent(azure.redirectUris.oAuth);
    const responseType = 'code';
    const scope = encodeURIComponent('user.read openid profile offline_access');
    const codeChallengeMethod = 'S256';
    const state = Math.random().toString(36).substring(2, 15);

    const url = `${authority}/oauth2/v2.0/authorize?client_id=${clientId}&response_type=${responseType}&redirect_uri=${redirectUri}&scope=${scope}&response_mode=query&state=${state}&code_challenge=${challenge}&code_challenge_method=${codeChallengeMethod}`;

    window.location.href = url;
  }
}

export default AuthService;
