<template>
  <auth-view-holder
    :title="$t('screens.login.title')"
    class="h-screen overflow-auto"
  >
    <form @submit.prevent="login">
      <template v-if="showCredentialsLogin">
        <VerticalStack>
          <labeled-default-input
            class="w-full"
            :label="$t('screens.login.email.label')"
            v-model="email"
            type="email"
            :error="onceSent && email === ''" />
          <labeled-default-input
            class="w-full"
            :label="$t('screens.login.password.label')"
            v-model="password"
            type="password"
            :error="onceSent && password === ''" />
          <default-checkbox
            class="pt-2"
            v-model="rememberMe"
            :label="$t('screens.login.stayLoggedIn.label')"
            size="large"
          />
        </VerticalStack>
        <default-button
          actionType="submit"
          :label="$t('screens.login.buttons.login')"
          class="w-full mt-8"
          auto-loading-state="global"
        />

        <VerticalStack class="w-full text-center mt-6 pb-10" alignment="center">
          <default-button v-if="inOfficeAddin" type="link" @click="openRegisterInBrowser" auto-loading-state="global">
            {{ $t('screens.login.links.register') }}
          </default-button>
          <default-button v-else to-route="/register" auto-loading-state="global">
            {{ $t('screens.login.links.register') }}
          </default-button>
          <default-button v-if="inOfficeAddin" type="link" @click="openForgotPasswordInBrowser" auto-loading-state="global">
            {{ $t('screens.login.links.forgotPassword') }}
          </default-button>
          <default-button v-else to-route="/forgot-password" auto-loading-state="global">
            {{ $t('screens.login.links.forgotPassword') }}
          </default-button>
        </VerticalStack>
      </template>
      <template v-else>
        <img
          :src="inOfficeAddin ? '/icons/powerpoint.svg' : '/icons/microsoft.svg'"
          class="w-20 h-20 mx-auto"
        />
      </template>
      <default-button
        :label="$t('screens.login.buttons.loginWithMicrosoft')"
        v-if="!inOfficeAddin"
        class="w-full mt-8"
        auto-loading-state="global"
        @click="loginWithMicrosoft"
      />
      <default-button
        :label="$t('screens.login.buttons.loginWithPowerPoint')"
        v-if="inOfficeAddin"
        class="w-full mt-8"
        auto-loading-state="global"
        @click="loginWithPowerPoint"
      />
      <p class="pt-4 text-foreground-light text-center">
        {{
          inOfficeAddin
            ? $t('screens.login.labels.powerPointLoginHint')
            : $t('screens.login.labels.microsoftLoginHint')
        }}
      </p>
      <default-button
        type="link"
        class="pt-4"
        v-if="!showCredentialsLogin"
        @click="showCredentialsLogin = true"
      >
        {{ $t('screens.login.buttons.useCredentials') }}
      </default-button>
    </form>
  </auth-view-holder>
</template>

<script lang="ts">
import { defineComponent, nextTick, ref } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import AuthService from '@/services/AuthService';
import { isAxiosError } from 'axios';
import AuthViewHolder from '@/components/layout/AuthViewHolder.vue';
import DefaultButton from '@/components/controls/DefaultButton.vue';
import VerticalStack from '@/components/layout/VerticalStack.vue';
import useMessages from '@/composables/use-messages';
import { useRouter } from 'vue-router';
import { inOfficeAddin } from '@/office-checker';
import { isAxiosErrorWithCode } from '@/utils/axios-error';
import SimpleModal from '@/components/layout/SimpleModal.vue';
import useAlert from '@/composables/use-alert';
import { AlertButtonStyle } from '@/types/AlertInfo';
import DefaultCheckbox from '@/components/controls/DefaultCheckbox.vue';
import { openExternalUrl } from '@/utils/powerpoint-helper';

export default defineComponent({
  components: {
    DefaultCheckbox,
    SimpleModal,
    DefaultButton,
    AuthViewHolder,
    VerticalStack,
  },
  setup() {
    const email = ref('');
    const password = ref('');
    const rememberMe = ref(false);
    const router = useRouter();

    const showCredentialsLogin = ref(false);

    const store = useStore();
    const { t } = useI18n();

    const onceSent = ref(false);

    const { setErrorMessage } = useMessages();
    const { showAlert, createAlertButton } = useAlert();

    const login = () => {
      onceSent.value = true;
      if (email.value === '' || password.value === '') {
        setErrorMessage(t('screens.login.errors.emptyFields'));
        return;
      }

      store.dispatch('startLoading');

      AuthService.login(email.value, password.value, rememberMe.value, router)
        .catch((error) => {
          if (isAxiosErrorWithCode(error, 401)) {
            setErrorMessage(t('screens.login.errors.loginFailed'));
          } else if (isAxiosError(error) && error.response?.status === 403) {
            setErrorMessage(t('screens.login.errors.licenseError'));
          } else {
            setErrorMessage(t('errors.unknown'));
          }
        })
        .finally(() => {
          store.dispatch('stopLoading');
        });
    };

    const loginWithMicrosoft = async () => {
      try {
        await AuthService.startAuthenticationWithMicrosoft();
      } catch (error) {
        setErrorMessage(t('screens.login.errors.microsoftLoginFailed', { error }));
      }
    };

    const loginWithPowerPoint = async () => {
      try {
        await AuthService.startAuthenticationWithPowerPoint(router);
      } catch (error) {
        if (isAxiosErrorWithCode(error, 404)) {
          // showNoPowerPointUserInfo.value = true;
          showAlert({
            message: 'screens.login.noPowerPointUserInfo.info',
            buttons: [
              createAlertButton({
                title: 'screens.login.noPowerPointUserInfo.button',
                action: async () => {
                  openLoginInBrowser();
                },
              }),
              createAlertButton({
                title: 'screens.login.noPowerPointUserInfo.backToLogin',
                type: AlertButtonStyle.Link,
                action: async () => {},
              }),
            ]
          });
        } else {
          setErrorMessage(t('screens.login.errors.microsoftLoginFailed', { error }));
        }
      }
    };

    const openRegisterInBrowser = () => {
      const url = `https://${window.location.host}/register`;
      openExternalUrl(url);
    };

    const openLoginInBrowser = () => {
      const url = `https://${window.location.host}/login`;
      openExternalUrl(url);
    };

    const openForgotPasswordInBrowser = () => {
      const url = `https://${window.location.host}/forgot-password`;
      openExternalUrl(url);
    };

    return {
      email,
      password,
      rememberMe,
      login,
      onceSent,
      loginWithMicrosoft,
      loginWithPowerPoint,
      inOfficeAddin,
      showCredentialsLogin,
      openRegisterInBrowser,
      openForgotPasswordInBrowser,
      openLoginInBrowser,
    };
  },
});
</script>
