import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuItem = _resolveComponent("MenuItem")!
  const _component_StackSpacer = _resolveComponent("StackSpacer")!
  const _component_SearchInput = _resolveComponent("SearchInput")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_vertical_stack = _resolveComponent("vertical-stack")!
  const _component_MenuDetailView = _resolveComponent("MenuDetailView")!
  const _component_VerticalStack = _resolveComponent("VerticalStack")!
  const _component_CenteredContent = _resolveComponent("CenteredContent")!

  return (!_ctx.userHasToCreateOrganization)
    ? (_openBlock(), _createBlock(_component_MenuDetailView, {
        key: 0,
        "content-scrollable": false
      }, {
        menu: _withCtx(() => [
          _createVNode(_component_MenuItem, {
            to: "/admin/files/content/images",
            exact: false,
            label: _ctx.$t('screens.files.menu.images')
          }, null, 8 /* PROPS */, ["label"]),
          _createVNode(_component_MenuItem, {
            to: "/admin/files/content/presentations",
            exact: false,
            label: _ctx.$t('screens.files.menu.presentations')
          }, null, 8 /* PROPS */, ["label"]),
          _createVNode(_component_MenuItem, {
            to: "/admin/files/content/slides",
            exact: false,
            label: _ctx.$t('screens.files.menu.slides')
          }, null, 8 /* PROPS */, ["label"]),
          _createVNode(_component_MenuItem, {
            to: "/admin/files/content/icons",
            exact: false,
            label: _ctx.$t('screens.files.menu.icons')
          }, null, 8 /* PROPS */, ["label"]),
          _createVNode(_component_MenuItem, {
            to: "/admin/files/content/animated_icons",
            exact: false,
            label: _ctx.$t('screens.files.menu.animatedIcons')
          }, null, 8 /* PROPS */, ["label"]),
          _createVNode(_component_StackSpacer),
          _createElementVNode("h3", null, _toDisplayString(_ctx.$t('screens.files.menu.tags')), 1 /* TEXT */),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tags, (tag) => {
            return (_openBlock(), _createBlock(_component_MenuItem, {
              to: `/admin/files/tags/${tag.slug}`,
              exact: false,
              label: tag.numberOfFiles ? `${tag.name} (${tag.numberOfFiles})` : tag.name,
              icon: "/icons/price-tag.svg"
            }, null, 8 /* PROPS */, ["to", "label"]))
          }), 256 /* UNKEYED_FRAGMENT */))
        ]),
        content: _withCtx(() => [
          _createVNode(_component_vertical_stack, { class: "h-full overflow-hidden" }, {
            default: _withCtx(() => [
              (_ctx.isRoot)
                ? (_openBlock(), _createBlock(_component_SearchInput, {
                    key: 0,
                    class: "w-96 mt-4",
                    modelValue: _ctx.searchString,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchString) = $event))
                  }, null, 8 /* PROPS */, ["modelValue"]))
                : _createCommentVNode("v-if", true),
              (_openBlock(), _createBlock(_component_router_view, { key: _ctx.subviewKey }))
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }))
    : (_openBlock(), _createBlock(_component_CenteredContent, {
        key: 1,
        class: "w-full"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_VerticalStack, {
            alignment: "center",
            class: "w-1/3 p-8"
          }, {
            default: _withCtx(() => [
              _createElementVNode("h1", null, _toDisplayString(_ctx.$t('screens.files.noOrganizationInfo.title')), 1 /* TEXT */),
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('screens.files.noOrganizationInfo.message')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }))
}