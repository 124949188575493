<template>
  <div>
    <HorizontalStack
      class="default-input pl-2 pr-1 py-1"
      :class="{ 'outline outline-1 outline-primary border-primary': inputFocused,
       'h-10': size === 'normal', 'h-8': size === 'small' }"
      :spacing="null"
      alignment="middle"
      @click="viewClicked"
    >
      <input
        ref="inputField"
        type="text"
        v-model="textInput"
        :placeholder="$t('components.searchInput.placeholder')"
        class="border-none bg-transparent outline-none focus:outline-none grow w-1/2"
        @focus="inputFocused = true"
        @blur="onInputBlur"
        @keydown="onKeyDown"
        @input="onInputChange"
      />
      <DefaultButton
        v-if="textInput !== ''"
        type="icon"
        size="small"
        @click="clear"
      >
        <img src="/icons/close.svg" alt="cancel search" class="w-3 h-3" />
      </DefaultButton>
      <div
        v-else
        class="grow-0 pr-1"
      >
        <img
          src="/icons/search.svg"
          alt="search"
          class="w-4 h-4"
        />
      </div>
    </HorizontalStack>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch, onMounted, PropType } from 'vue';
import HorizontalStack from '@/components/layout/HorizontalStack.vue';
import DefaultButton from '@/components/controls/DefaultButton.vue';
import { useStore } from 'vuex';

export default defineComponent({
  components: { DefaultButton, HorizontalStack },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    size: {
      type: String as PropType<'normal' | 'small'>,
      default: 'normal',
    },
  },
  emits: ['update:modelValue', 'focus', 'blur'],
  setup(props, { emit }) {
    const store = useStore();

    const textInput = ref(props.modelValue);
    const inputField = ref<HTMLInputElement | null>(null);
    const inputFocused = ref(false);
    let typingTimeout: ReturnType<typeof setTimeout> | null = null;

    const viewClicked = () => {
      inputField.value?.focus();
    };

    const onInputBlur = () => {
      inputFocused.value = false;
    };

    const onInputChange = () => {
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
      typingTimeout = setTimeout(() => {
        emit('update:modelValue', textInput.value);
      }, store.getters.searchDebounceTime);
    };

    watch(
      () => props.modelValue,
      (value) => {
        textInput.value = value;
      }
    );

    watch(inputFocused, (value) => {
      if (value) {
        emit('focus');
      } else {
        emit('blur');
      }
    });

    const clear = () => {
      textInput.value = '';
      emit('update:modelValue', '');
    };

    const onKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        if (textInput.value === '') {
          inputField.value?.blur();
        } else {
          clear();
        }
      }
    };

    const focus = () => {
      inputField.value?.focus();
    };

    return {
      textInput,
      inputFocused,
      inputField,
      viewClicked,
      onInputBlur,
      onInputChange,
      clear,
      onKeyDown,
      focus,
    };
  },
});
</script>
