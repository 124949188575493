<template>
  <div
    class="absolute top-0 bottom-0 inset-0"
    :class="{
      'bg-blurry': theme === 'light',
      'bg-blurry-dark': theme === 'dark',
    }"
  >
    <centered-content class="w-full h-full overflow-hidden py-8">
      <slot />
    </centered-content>
  </div>
</template>
<script lang="ts">
import CenteredContent from '@/components/layout/CenteredContent.vue';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  components: { CenteredContent },
  props: {
    theme: {
      type: String as PropType<'light' | 'dark'>,
      default: 'dark',
    },
  }
});
</script>
