<script lang="ts">
import { defineComponent, onMounted, onBeforeUnmount } from 'vue';
import DefaultButton from '@/components/controls/DefaultButton.vue';
import StackSpacer from '@/components/layout/StackSpacer.vue';
import TopStrip from '@/components/layout/TopStrip.vue';
import MenuItem from '@/components/controls/MenuItem.vue';
import OverlayBackground from '@/components/layout/OverlayBackground.vue';

export default defineComponent({
  name: 'ModalView',
  components: {
    OverlayBackground,
    TopStrip,
    StackSpacer,
    MenuItem,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    showMenu: {
      type: Boolean,
      default: true,
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
    closeOnEscape: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String as () => 'medium' | 'large',
      default: 'medium',
    },
  },
  emits: ['close'],
  setup(props, { emit }) {
    const handleClose = () => {
      emit('close');
    };

    const onKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && props.closeOnEscape) {
        handleClose();
      }
    };

    onMounted(() => {
      window.addEventListener('keydown', onKeyDown);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('keydown', onKeyDown);
    });

    return {
      handleClose,
    };
  },
});
</script>

<template>
  <OverlayBackground>
    <VerticalStack :spacing="null" class="border h-full border-border bg-background rounded-lg shadow-2xl
    py-0 overflow-hidden"
      :class="size === 'medium'
        ? 'sm:w-5/6 md:w-4/5 lg:w-3/5 xl:w-3/5 2xl:w-3/5 w-5/6'
        : 'sm:w-5/6 md:w-5/6 lg:w-4/5 xl:w-4/5 2xl:w-4/5 w-5/6'"
    >
      <TopStrip v-if="showMenu" class="h-14 z-30 shrink-0 grow-0 w-full" size="small">
        <h2 class="m-0 font-normal sm:font-bold">{{ title }}</h2>
        <StackSpacer size="stretch" />
        <img
          v-if="showCloseButton"
          src="/icons/close.svg"
          alt="close"
          @click.stop="handleClose"
          class="h-4 aspect-square cursor-pointer grow-0"
        />
      </TopStrip>
      <div class="w-full grow overflow-auto sm:px-8 px-4 pb-8">
        <slot id="modal-content"/>
      </div>
    </VerticalStack>
  </OverlayBackground>
</template>
