<template v-if="tabs.length > 0">
  <HorizontalStack id="tab-bar" :spacing="null" alignment="bottom">
    <stack-spacer size="extrasmall" class="border-b border-border h-full" />
    <router-link
      v-for="tab in tabs"
      :key="tab.to"
      :to="tab.to"
      exact-active-class="active-link"
      active-class="active-link"
      class="overflow-hidden"
      :class="isActive(tab) ? 'active-link' : ''"
    >
      <div class="tab-element">
        <p class="text-sm overflow-hidden text-center mx-4">{{ tab.title }}</p>
      </div>
    </router-link>
    <stack-spacer size="stretch" class="border-b border-border h-full" />
  </HorizontalStack>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import HorizontalStack from '@/components/layout/HorizontalStack.vue';
import { useRoute } from 'vue-router';
import StackSpacer from '@/components/layout/StackSpacer.vue';

interface TabControlTab {
  title: string;
  to: string;
  fit?: (current: string) => boolean;
}

export default defineComponent({
  components: {StackSpacer, HorizontalStack},
  props: {
    tabs: {
      type: Array as () => TabControlTab[],
      required: true,
    },
  },
  setup() {
    const route = useRoute();

    const isActive = (tab: TabControlTab) => {
      return route.path.startsWith(tab.to) || (tab.fit ? tab.fit(route.path) : false);
    }

    return {
      isActive,
    };
  },
});
</script>

<style scoped>

.active-link p {
  @apply font-bold;
}

.tab-element {
  @apply border border-border rounded-t-lg p-1.5;
}

.active-link .tab-element {
  @apply border-b-0 border-x-2 border-t-2;
}

</style>
