class MouseHelperService {
  private static instance: MouseHelperService;
  private mouseX: number | null = null;
  private mouseY: number | null = null;

  private constructor() {
    this.initializeListener();
  }

  public static getInstance(): MouseHelperService {
    if (!MouseHelperService.instance) {
      MouseHelperService.instance = new MouseHelperService();
    }
    return MouseHelperService.instance;
  }

  private initializeListener(): void {
    document.addEventListener('mousemove', this.updateMousePosition.bind(this));
  }

  private updateMousePosition(event: MouseEvent): void {
    console.log('Mouse position updated');
    this.mouseX = event.clientX;
    this.mouseY = event.clientY;
  }

  public getMousePosition(): { x: number | null, y: number | null } {
    return { x: this.mouseX, y: this.mouseY };
  }
}

export default MouseHelperService;
