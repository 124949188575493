<template>
  <router-link
    :to="to"
    active-class="link-active"
    :class="hovered ? 'hovered' : ''"
    @mouseover="hovered = true"
    @mouseleave="hovered = false"
  >
    <VerticalStack
      alignment="center" :spacing="null">
      <div class="p-2 active-background default-transition"
        :class="{ 'p-2 rounded-xl': !collapsed, 'p-1 rounded-lg': collapsed }"
      >
        <img
          :src="icon"
          class="aspect-square active-icon icon default-transition"
          :class="{ 'w-6': !collapsed, 'w-5': collapsed }"
          alt="icon"
        />
      </div>
      <Transition>
        <span
          class="active-title text-foreground text-center default-transition pt-1"
          :class="{ 'opacity-100': !collapsed, 'opacity-0': collapsed }"
        >
          {{ title }}
        </span>
      </Transition>
    </VerticalStack>
  </router-link>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

export default defineComponent({
  props: {
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    to: {
      type: String,
      required: true,
    },
    collapsed: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const hovered = ref(false);

    return {
      hovered,
    };
  },
});
</script>

<style>
.link-active .active-title {
  @apply font-bold;
}

.link-active .active-background {
  @apply bg-gradient-primary;
}

.hovered .active-background {
  @apply bg-background-ultraDark;
}

.hovered .active-title {
  @apply font-bold;
}

.link-active .active-icon {
  @apply invert;
}
</style>
