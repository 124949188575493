import TagInfo from '@/types/TagInfo';
import store from '@/store/store';
import axios from 'axios';
import { api } from '@/config';
import { PageResult } from '@/types/PageResult';
import FileInfo from '@/types/FileInfo';

class TagsService {

  static async getAllTags(): Promise<TagInfo[]> {
    const slug = store.getters.organizationSlug;
    if (!slug) {
      return [];
    }

    const response = await axios.get<TagInfo[]>(`${api.v1}/${slug}/content/tags`);
    return response.data;
  }

  static async getTagDetail(slug: string): Promise<TagInfo> {
    const organizationSlug = store.getters.organizationSlug;
    if (!organizationSlug) {
      throw new Error('Organization slug is not set');
    }

    const response = await axios.get<TagInfo>(`${api.v1}/${organizationSlug}/content/tags/${slug}`);
    return response.data;
  }

  static async getTagFiles(slug: string, size: number | null, page: number): Promise<PageResult<FileInfo>> {
    const organizationSlug = store.getters.organizationSlug;
    if (!organizationSlug) {
      throw new Error('Organization slug is not set');
    }

    const params = new URLSearchParams();
    params.append('sort', 'createdAt,desc');
    params.append('page', page.toString())
    params.append('size', `${size || 999999999}`)

    const response = await axios.get<PageResult<FileInfo>>(
        `${api.v1}/${organizationSlug}/content/tags/${slug}/files?page=${page}`,
        {
          params
        }
      );
    return response.data;
  }

  static async deleteTag(slug: string): Promise<void> {
    const organizationSlug = store.getters.organizationSlug;
    if (!organizationSlug) {
      throw new Error('Organization slug is not set');
    }

    await axios.delete(`${api.v1}/${organizationSlug}/content/tags/${slug}`);
  }
}

export default TagsService;
