import { vModelCheckbox as _vModelCheckbox, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HorizontalStack = _resolveComponent("HorizontalStack")!

  return (_openBlock(), _createBlock(_component_HorizontalStack, {
    alignment: "middle",
    spacing: 2
  }, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("input", {
        type: "checkbox",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.checked) = $event)),
        onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"])),
        class: _normalizeClass({ 'w-5 h-5': _ctx.size === 'large',})
      }, null, 2 /* CLASS */), [
        [_vModelCheckbox, _ctx.checked]
      ]),
      _createElementVNode("span", null, _toDisplayString(_ctx.label), 1 /* TEXT */)
    ]),
    _: 1 /* STABLE */
  }))
}