<template>
  <div
    class="flex items-center justify-center"
  >
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  setup() {

    return {};
  },
});
</script>
