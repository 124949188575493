<template>
  <WrappingFlow :gap="gap">
    <template v-for="(breadCrumb, index) in breadCrumbs">
      <span
        class="px-2 font-bold"
        :class="{
          'text-sm': size === 'small',
        }"
        v-if="breadCrumb.path === currentPath || breadCrumb.path == 0"
      >
        {{ breadCrumb.name }}
      </span>
      <default-button
        type="link"
        v-else
        class="p-0 m-0"
        :class="{
          'text-sm': size === 'small',
        }"
        @click="goBackTo(breadCrumb)"
      >{{ breadCrumb.name }}</default-button>
      <span class="text-sm" v-if="index < breadCrumbs.length - 1">&gt;</span>
    </template>
  </WrappingFlow>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import BreadCrump from '@/types/BreadCrump';
import HorizontalStack from '@/components/layout/HorizontalStack.vue';
import DefaultButton from '@/components/controls/DefaultButton.vue';
import { useRoute, useRouter } from 'vue-router';
import WrappingFlow, { Gap } from '@/components/layout/WrappingFlow.vue';

export default defineComponent({
  components: {
    WrappingFlow,
    DefaultButton,
    HorizontalStack
  },
  props: {
    breadCrumbs: {
      type: Array as () => BreadCrump[],
      required: true,
    },
    gap: {
      type: String as PropType<Gap>,
      default: 'small',
    },
    size: {
      type: String as PropType<'small' | 'large'>,
      default: 'small',
    }
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const currentPath = route.path;

    const goBackTo = (breadCrumb: BreadCrump) => {
      if (typeof breadCrumb.path === 'string') {
        router.replace(breadCrumb.path);
      } else {
        router.go(-breadCrumb.path);
      }
    };

    return {
      goBackTo,
      currentPath,
    };
  },
});
</script>
