import { useStore } from 'vuex';
import { i18n } from '@/i18n/i18n-setup';

const useMessages = () => {
  const store = useStore();

  const setErrorMessage = (message: string) => {
    store.dispatch('setErrorMessage', i18n.global.t(message));
  };

  const setSuccessMessage = (message: string) => {
    store.dispatch('setSuccessMessage', i18n.global.t(message));
  };

  return {
    setErrorMessage,
    setSuccessMessage,
  };
};

export default useMessages;
