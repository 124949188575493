import { RouteRecordRaw } from 'vue-router';
import AddInView from '@/views/addin/AddInView.vue';
import store from '@/store/store';
import { AddinSettings } from '@/types/OrganizationSettings';

const contentChildren: (area: string, defaultRedirect: string) => Array<RouteRecordRaw> = (area, defaultRedirect) => [
  {
    path: '',
    name: 'addin-content-base-' + area,
    component: () => import('@/views/addin/AddInView.vue'),
    beforeEnter: (to, from, next) => {
      if (to.path.includes(`/addin/content/${area}/`)) {
        next();
        return;
      }
      const base = getInBaseAreaRedirectLogic(defaultRedirect);
      if (base) {
        next(`/addin/content/${area}/${base}`);
      } else {
        console.error(`No base found for area ${area}. Navigating to loading...`);
        next('/loading')
      }
    },
  },
  {
    path: 'favorites/:type',
    name: 'addin-content-favorites-' + area,
    component: () => import('@/views/addin/content/ContentFavoritesDetailsView.vue'),
  },
  {
    path: 'last-used/:type',
    name: 'addin-content-last-used-' + area,
    component: () => import('@/views/addin/content/ContentLastUsedDetailsView.vue'),
  },
  {
    path: ':type',
    name: 'addin-content-' + area,
    component: () => import('@/views/addin/content/ContentFilesView.vue'),
  },
  {
    path: ':type/:categorySlug',
    name: 'addin-content-category' + area,
    component: () => import('@/views/addin/content/ContentCategoryDetailsView.vue'),
  },
];

const getRedirectBaseLogic = (): string | null => {
  const addinSettings = store.getters.addinSettings as AddinSettings | null;
  if (!store.getters.user) {
    return null;
  }
  if (!addinSettings) {
    return '/addin/content/imagepool';
  }
  if (!addinSettings.imagesHidden || !addinSettings.onlineImagesHidden) {
    return '/addin/content/imagepool';
  }
  if (!addinSettings.presentationsHidden || !addinSettings.slidesHidden) {
    return '/addin/content/powerpoint';
  }
  if (!addinSettings.iconsHidden || !addinSettings.animatedIconsHidden) {
    return '/addin/content/iconpool';
  }
  return null;
};

const getInBaseAreaRedirectLogic = (base: string): string | null => {
  const addinSettings = store.getters.addinSettings as AddinSettings | null;
  if (!store.getters.user) {
    return null;
  }
  if (!addinSettings) {
    return 'images';
  }
  if (base === 'imagepool') {
    return !addinSettings.imagesHidden ? 'images' : 'onlineImages';
  }
  if (base === 'powerpoint') {
    return !addinSettings.presentationsHidden ? 'presentations' : 'slides';
  }
  if (base === 'iconpool') {
    return !addinSettings.iconsHidden ? 'icons' : 'animated_icons';
  }
  return null;
}

const addInRoutes: Array<RouteRecordRaw> = [
  {
    path: '/addin',
    name: 'addin',
    component: AddInView,
    beforeEnter: (to, from, next) => {
      if (to.path.includes('/addin/content/')) {
        next();
        return;
      }
      const dynamicRedirect = getRedirectBaseLogic();
      if (dynamicRedirect) {
        next(dynamicRedirect);
      } else {
        console.error('No dynamicRedirect found. Navigating to loading...');
        next('/loading')
      }
    },
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'content/imagepool',
        name: 'addin-content-imagepool',
        component: () => import('@/views/addin/content/ContentView.vue'),
        children: contentChildren('imagepool', 'imagepool'),
      },
      {
        path: 'content/iconpool',
        name: 'addin-content-iconpool',
        component: () => import('@/views/addin/content/ContentView.vue'),
        children: contentChildren('iconpool', 'iconpool'),
      },
      {
        path: 'content/powerpoint',
        name: 'addin-content-powerpoint',
        component: () => import('@/views/addin/content/ContentView.vue'),
        children: contentChildren('powerpoint', 'powerpoint'),
      },
    ]
  },
];

export default addInRoutes;
