import UserNotification from '@/types/UserNotification';
import axios from 'axios';
import { api } from '@/config';
import OrganizationInvitation from '@/types/OrganizationInvitation';

class NotificationService {
  static async notifications(): Promise<UserNotification[]> {
    const response = await axios.get<UserNotification[]>(`${api.v1}/me/notifications`);
    return response.data.map((notification) => new UserNotification(notification));
  }

  static async setRead(notification: UserNotification): Promise<void> {
    await axios.patch(`${api.v1}/me/notifications/${notification.id}/${notification.type}/read`);
  }
}

export default NotificationService;
