<template>
  <labeled-default-input
    class="w-full"
    :label="label"
    type="file"
    :accept="accept"
    @change="handleFileChange"
  />
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import LabeledDefaultInput from '@/components/controls/LabeledDefaultInput.vue';

export default defineComponent({
  components: {
    LabeledDefaultInput,
  },
  props: {
    modelValue: {
      type: Object as PropType<File | null>,
      default: null,
    },
    label: {
      type: String,
      required: true,
    },
    accept: {
      type: String,
      required: true,
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const handleFileChange = (event: Event) => {
      const input = event.target as HTMLInputElement;
      if (input.files?.length) {
        emit('update:modelValue', input.files[0]);
      } else {
        emit('update:modelValue', null);
      }
    };

    return {
      handleFileChange,
    };
  },
});
</script>
