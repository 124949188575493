<script setup lang="ts">

import MenuDetailView from '@/components/layout/MenuDetailView.vue';
import MenuItem from '@/components/controls/MenuItem.vue';

</script>

<template>
  <MenuDetailView>
    <template #menu>
      <MenuItem
        to="/admin/me"
        icon="/icons/user.svg"
        :label="$t('screens.profile.title')"
      />
      <MenuItem icon="/icons/key.svg"
        to="/admin/me/password"
        permissions="USER_CHANGE_AUTHENTICATION"
        :label="$t('screens.profile.authentication.title')"
        v-if="$user()?.azureSSO === false"
      />
      <MenuItem
        icon="/icons/devices.svg"
        to="/admin/me/active-sessions"
        permissions="USER_ACTIVE_LOGINS_READ_SELF"
        :label="$t('screens.profile.activeLogins.title')"
      />
    </template>
    <template #content>
      <router-view />
    </template>
  </MenuDetailView>
</template>
