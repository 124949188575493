import { toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.system.environment !== 'prod')
    ? (_openBlock(), _createElementBlock("span", {
        key: 0,
        class: _normalizeClass(["text-xs text-foreground-light", { 'clickable': _ctx.clickable }]),
        onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.showChangelog && _ctx.showChangelog(...args)), ["stop"]))
      }, _toDisplayString(`${_ctx.frontendVersion} | ${_ctx.backendVersion}`), 3 /* TEXT, CLASS */))
    : _createCommentVNode("v-if", true)
}