<template>
  <HorizontalStack
    class="w-full sm:px-8 px-4 grow-0 shrink-0"
    :class="[
      size === 'small' ? 'h-10' : 'h-14',
    ]"
    alignment="middle"
    :spacing="spacing"
  >
    <slot />
  </HorizontalStack>
</template>
<script setup lang="ts">
import { PropType } from 'vue';
import { Spacing } from '@/router/spacing-helpers';

defineProps(
  {
    size: {
      type: String as PropType<'small' | 'large'>,
      default: 'large',
    },
    spacing: {
      type: Number as PropType<Spacing>,
      default: 2,
    },
  }
);
</script>
