import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, withKeys as _withKeys, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0991ec98"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HorizontalStack = _resolveComponent("HorizontalStack")!

  return (_openBlock(), _createBlock(_component_HorizontalStack, {
    id: "code-six-digit-code-input-container",
    class: "max-w-72 w-full",
    spacing: 2,
    onClick: _ctx.setActiveField,
    onKeyup: _withKeys(_ctx.setActiveField, ["space"])
  }, {
    default: _withCtx(() => [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(6, (n) => {
        return _createElementVNode("div", {
          key: n,
          id: `code-${n-1}`,
          class: _normalizeClass(["code-input border border-border rounded-lg shadow-md p-2 pt-2 w-full text-center h-12 text-2xl", { 'ring-error shadow-error-light ring-2': _ctx.error,
      'bg-active ring-foreground ring-2': n-1 === _ctx.activeIndex }]),
          tabindex: "0",
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.setActiveField && _ctx.setActiveField(...args)), ["stop"])),
          onKeyup: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.setActiveField && _ctx.setActiveField(...args)), ["space"]))
        }, _toDisplayString(_ctx.codeChars[n-1]), 43 /* TEXT, CLASS, PROPS, NEED_HYDRATION */, _hoisted_1)
      }), 64 /* STABLE_FRAGMENT */))
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["onClick", "onKeyup"]))
}