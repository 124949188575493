import { createStore } from 'vuex';
import User from '@/types/User';
import { AlertInfo } from '@/types/AlertInfo';
import ModalInfo from '@/types/ModalInfo';
import UserNotification from '@/types/UserNotification';
import NotificationService from '@/services/NotificationService';
import MeService from '../services/MeService';
import { Organization } from '@/types/Organization';
import OrganizationService from '@/services/OrganizationService';
import ContentCategory from '@/types/ContentCategory';
import TagInfo from '@/types/TagInfo';
import TagsService from '@/services/TagsService';
import AppConfigModule from '@/store/appConfigModule';
import OrganizationSettingsModule from '@/store/organizationConfigModule';
import LoadingStateModule from '@/store/loadingStateModule';
import TrackingService from '@/services/TrackingService';

interface State {
  user: User | null;
  allTags: TagInfo[];
  userNotifications: UserNotification[];
  organization: Organization | null;
  errorMessage: string | null;
  successMessage: string | null;
  alertInfo: AlertInfo | null;
  alertError: string | null;
  userLoaded: boolean
  modalInfo: ModalInfo | null;
  modalCloseHook: (() => boolean) | null;
  selectedCategory: ContentCategory | null;
}

const store =  createStore({
  modules: {
    appConfig: AppConfigModule,
    organizationSettings: OrganizationSettingsModule,
    loadingStateModule: LoadingStateModule,
  },
  state: {
    user: null,
    allTags: [],
    userNotifications: [],
    organization: null,
    errorMessage: null,
    successMessage: null,
    alertInfo: null,
    alertError: null,
    userLoaded: false,
    modalInfo: null,
    modalCloseHook: null,
    selectedCategory: null,
  },
  getters: {
    organizationSlug: (state): string | null => {
      if (!state.user) {
        return null;
      }
      return (state.user as User).organizationSlug;
    },
    organizationName: (state): string | null => {
      if (!state.user) {
        return null;
      }
      return (state.user as User).organizationName;
    },
    selectedCategory: (state): ContentCategory | null => state.selectedCategory,
    tags: (state): TagInfo[] => state.allTags,
    organization: (state): Organization | null => state.organization,
    user: (state): User | null => state.user,
    userNotifications: (state): UserNotification[] => state.userNotifications,
    modalInfo: (state): ModalInfo | null => state.modalInfo,
    modalCloseHook: (state): (() => Promise<boolean>) | null => state.modalCloseHook,
    alertInfo: (state): AlertInfo | null => state.alertInfo,
  },
  mutations: {
    SET_USER(state: State, user: User) {
      state.user = user;
    },
    SET_ORGANIZATION(state: State, organization: Organization) {
      state.organization = organization;
    },
    SET_USER_LOADED(state: State) {
      state.userLoaded = true;
    },
    SET_ERROR_MESSAGE(state: State, message: string | null) {
      state.errorMessage = message;
    },
    SET_SUCCESS_MESSAGE(state: State, message: string | null) {
      state.successMessage = message;
    },
    SET_ALERT_INFO(state: State, alertInfo: AlertInfo | null) {
      state.alertInfo = alertInfo;
    },
    SET_MODAL_INFO(state: State, modalInfo: ModalInfo | null) {
      state.modalInfo = modalInfo;
      if (!modalInfo) {
        state.modalCloseHook = null;
      }
    },
    SET_ALERT_ERROR(state: State, message: string | null) {
      state.alertError = message;
    },
    SET_USER_NOTIFICATIONS(state: State, notifications: UserNotification[]) {
      state.userNotifications = notifications;
    },
    SET_SELECTED_CATEGORY(state: State, category: ContentCategory | null) {
      state.selectedCategory = category;
    },
    SET_ALL_TAGS(state: State, tags: TagInfo[]) {
      state.allTags = tags;
    },
    SET_MODAL_CLOSE_HOOK(state: State, hook: (() => boolean) | null) {
      state.modalCloseHook = hook;
    }
  },
  actions: {
    async loadUser({ commit, dispatch }) {
      const userData: User = await MeService.me()
        .finally(() => commit('SET_USER_LOADED'));
      commit('SET_USER', userData);
      if (userData.permissions.includes('ORGANIZATION_READ')) {
        await dispatch('loadOrganization');
      }
    },
    async updateUser({ commit }, { firstName, lastName }) {
      commit('START_LOADING');
      try {
        const userData: User = await MeService.editMe(firstName, lastName);
        commit('SET_USER', userData);
      } finally {
        commit('STOP_LOADING');
      }
    },
    async loadOrganization({ commit, dispatch }) {
      commit('START_LOADING');
      try {
        const organization = await OrganizationService.getOrganizationInfo();
        if (organization.slug) {
          await dispatch('loadOrganizationSettings', organization.slug);
        }
        commit('SET_ORGANIZATION', organization);
      } finally {
        commit('STOP_LOADING');
      }
    },
    async loadAllTags({ commit }) {
      const tags = await TagsService.getAllTags();
      commit('SET_ALL_TAGS', tags);
    },
    async loadUserNotifications({ commit }) {
      const notifications = await NotificationService.notifications();
      commit('SET_USER_NOTIFICATIONS', notifications);
    },
    async setNotificationRead({ commit }, notification: UserNotification) {
      await NotificationService.setRead(notification);
      const notifications = await NotificationService.notifications();
      commit('SET_USER_NOTIFICATIONS', notifications);
    },
    setErrorMessage({ commit }, message: string | null) {
      commit('SET_ERROR_MESSAGE', message);
    },
    setSuccessMessage({ commit }, message: string | null) {
      commit('SET_SUCCESS_MESSAGE', message);
    },
    showAlert({ commit }, alertInfo: AlertInfo) {
      commit('SET_ALERT_INFO', alertInfo);
      commit('SET_ALERT_ERROR', null);
    },
    closeAlert({ commit }) {
      commit('SET_ALERT_INFO', null);
      commit('SET_ALERT_ERROR', null);
    },
    showModal({ commit }, modalInfo: ModalInfo) {
      commit('SET_MODAL_INFO', modalInfo);
    },
    closeModal({ commit }) {
      commit('SET_MODAL_INFO', null);
    },
    setAlertError({ commit }, message: string | null) {
      commit('SET_ALERT_ERROR', message);
    },
    setSelectedCategory({ commit }, category: ContentCategory | null) {
      commit('SET_SELECTED_CATEGORY', category);
    },
    setModalCloseHook({ commit }, hook: (() => Promise<boolean>) | null) {
      commit('SET_MODAL_CLOSE_HOOK', hook);
    },
  },
});

export default store;
