import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-center max-w-72 w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainLogoComponent = _resolveComponent("MainLogoComponent")!
  const _component_default_button = _resolveComponent("default-button")!
  const _component_VerticalStack = _resolveComponent("VerticalStack")!

  return (_openBlock(), _createBlock(_component_VerticalStack, {
    class: "w-full p-8",
    alignment: "center"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_MainLogoComponent),
      _createElementVNode("h2", null, _toDisplayString(_ctx.$t('screens.login.licenseError.title')), 1 /* TEXT */),
      _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t('screens.login.licenseError.text')), 1 /* TEXT */),
      _createVNode(_component_default_button, {
        label: _ctx.$t('screens.login.licenseError.retry'),
        class: "max-w-72 w-full",
        onClick: _ctx.retry
      }, null, 8 /* PROPS */, ["label", "onClick"]),
      _createVNode(_component_default_button, {
        type: "secondary",
        label: _ctx.$t('screens.login.licenseError.backToLogin'),
        class: "max-w-72 w-full",
        onClick: _ctx.backToLogin
      }, null, 8 /* PROPS */, ["label", "onClick"])
    ]),
    _: 1 /* STABLE */
  }))
}