import { ListItem } from '@/types/LitItem';
import { upperSnakeCaseToCamelCase } from '@/utils/string-conversions';

class UserNotification implements ListItem {
  id: number;

  type: string;

  issuer: string | null;

  read: boolean;

  issueDate: Date;

  constructor({
    id,
    type,
    issuer,
    read,
    issueDate,
  }: UserNotification) {
    this.id = id;
    this.type = type;
    this.issuer = issuer;
    this.read = read;
    this.issueDate = issueDate;
  }

  get title(): string {
    return `elements.notifications.${upperSnakeCaseToCamelCase(this.type)}.title`;
  }
}

export default UserNotification;
