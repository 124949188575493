<template>
  <div class="w-screen h-screen overflow-hidden relative" id="main-container">
    <ModalViewWrapper v-if="modal" :modal="modal" class="absolute z-20" />
    <AlertDialog v-if="alert" :alert="alert" />
    <div v-if="errorMessage || successMessage" class="fixed bottom-0 left-1/2 transform -translate-x-1/2 p-4 bg-gray-200 z-50">
      <message-component
        :message="errorMessage"
        class="mb-4"
        type="error"
        auto-remove
        @remove="clearErrorMessage"
      />
      <message-component
        :message="successMessage"
        class="mb-4"
        type="success"
        auto-remove
        @remove="clearSuccessMessage"
      />
    </div>
    <router-view v-if="loaded" class="relative z-0 overflow-visible"/>
    <loading-view v-else />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch, } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { AlertButtonStyle, AlertInfo } from '@/types/AlertInfo';
import AlertDialog from '@/components/AlertDialog.vue';
import LoadingView from '@/views/general/LoadingView.vue';
import MessageComponent from '@/components/MessageComponent.vue';
import ModalViewWrapper from '@/components/layout/ModalViewWrapper.vue';
import AuthService from '@/services/AuthService';
import VersionInfo from '@/components/viewelements/VersionInfo.vue';
import useAlert from '@/composables/use-alert';
import { getCookie, setCookie } from '@/utils/cookie-helpers';
import { system } from '@/config';
import TrackingService from '@/services/TrackingService';
import MouseHelperService from '@/services/MouseHelperService';

export default defineComponent({
  components: {
    VersionInfo,
    ModalViewWrapper,
    LoadingView,
    AlertDialog,
    MessageComponent,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const { showAlert, createAlertButton } = useAlert();

    const errorMessage = ref('');
    const successMessage = ref('');
    const loaded = ref(false);

    const alert = computed(() => store.state.alertInfo);
    const modal = computed(() => store.state.modalInfo);

    watch (alert, (newValue) => {
      console.log(`alert is: ${newValue}`);
    });

    const mouseHelperService = MouseHelperService.getInstance();

    watch(() => store.getters.user, (user) => {
      if (user) {
        if (user.trackingConsent === null) {
          showAlert({
            title: 'screens.main.cookieConsentAlert.title',
            message: 'screens.main.cookieConsentAlert.message',
            buttons: [
              createAlertButton({
                title: 'screens.main.cookieConsentAlert.accept',
                type: AlertButtonStyle.Primary,
                action: async () => {
                  await TrackingService.enable();
                }
              }),
              createAlertButton({
                title: 'screens.main.cookieConsentAlert.reject',
                type: AlertButtonStyle.Secondary,
                action: async () => {
                  await TrackingService.disable();
                }
              }),
            ]
          });
        } else {
          if (user.trackingConsent === true) {
            TrackingService.enableAppInsightsTracking(user);
          } else {
            TrackingService.disableAppInsightsTracking();
          }
        }
      } else {
        TrackingService.disableAppInsightsTracking();
      }
    });

    watch(
      () => store.state.errorMessage,
      (newErrorMessage) => {
        errorMessage.value = newErrorMessage;
      },
    );

    watch(
      () => store.state.successMessage,
      (newSuccessMessage) => {
        successMessage.value = newSuccessMessage;
      },
    );

    const clearErrorMessage = () => {
      store.dispatch('setErrorMessage', null);
    };

    const clearSuccessMessage = () => {
      store.dispatch('setSuccessMessage', null);
    };

    onMounted(async () => {
      AuthService.checkOfficeAndPreauth(router, true)
        .then( () => Promise.all(
          [
            store.dispatch('loadAppConfig'),
            store.dispatch('loadUser'),
          ]
        ))
        .then(() => store.dispatch('loadOrganization'))
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        .catch(() => { })
        .finally(() => {
          loaded.value = true;
          if (route.path === '/oauth2/azure') return;
          if (route.path === '/azure/admin-consent') return;
          let nextPath = route.path;
          if (route.path === '/loading') {
            nextPath = '/';
          }
          router.replace({
            path: nextPath,
            query: {
              ...route.query,
              l: `${new Date().getTime()}-${Math.random()}`
            }
          });
        });
    });

    return {
      errorMessage,
      successMessage,
      clearErrorMessage,
      clearSuccessMessage,
      alert,
      modal,
      loaded,
    };
  },
});
</script>
