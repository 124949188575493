class User {
  email = '';

  firstName = '';

  lastName = '';

  emailValidated = false;

  permissions: string[] = [];

  twoFAType: string | null = '';

  organizationSlug: string | null = null;

  organizationName: string | null = null;

  azureSSO = false;

  superUser = false;

  reference = '';

  trackingConsent: string | null = null;

  constructor({
    email,
    firstName,
    lastName,
    emailValidated,
    permissions,
    twoFAType,
    organizationSlug,
    organizationName,
    azureSSO,
    superUser,
    reference,
    trackingConsent,
  }: User) {
    this.email = email;
    this.firstName = firstName;
    this.lastName = lastName;
    this.emailValidated = emailValidated;
    this.permissions = permissions;
    this.twoFAType = twoFAType;
    this.organizationSlug = organizationSlug;
    this.organizationName = organizationName;
    this.azureSSO = azureSSO;
    this.superUser = superUser;
    this.reference = reference;
    this.trackingConsent = trackingConsent;
  }

  allowed(permission: string): boolean {
    return this.permissions.includes(permission);
  }

  get twoFAEnabled(): boolean {
    return this.twoFAType !== null;
  }
}

export default User;
