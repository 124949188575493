<script lang="ts">
import {
  defineComponent,
  computed,
  ref,
  toRefs,
  onMounted,
  onUnmounted, useSlots, inject,
} from 'vue';
import { useStore } from 'vuex';
import VerticalStack from '@/components/layout/VerticalStack.vue';
import HorizontalStack from '@/components/layout/HorizontalStack.vue';

export default defineComponent({
  components: { VerticalStack },
  props: {
    to: {
      type: String,
      default: null,
      required: false,
    },
    exact: {
      type: Boolean,
      default: true,
    },
    permissions: {
      type: String,
      default: '',
      required: false,
    },
    icon: {
      type: String,
      default: '',
      required: false,
    },
    label: {
      type: String,
      default: '',
      required: false,
    },
    hinted: {
      type: Boolean,
      default: false,
      required: false,
    },
    active: {
      type: Boolean,
      default: false,
      required: false,
    },
    padded: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  emits: ['click'],
  setup(props, { emit, attrs }) {
    const store = useStore();
    const user = ref(store.state.user);
    const isSubmenuOpen = ref(false);
    const menuRef = ref<HTMLElement | null>(null);
    const menuMainRef = ref<HTMLElement | null>(null);
    const menuHolderRef = ref<HTMLElement | null>(null);
    const shouldAlignRight = ref(false);
    const closeMenuTimeout = ref<number | null>(null);
    const slots = useSlots();

    const isVerticalStack = inject('isVerticalStack', false);

    const classes = 'text-foreground hover:text-foreground '
      + 'whitespace-pre rounded-md flex flex-row items-center first:mt-0 mt-0.5 relative '
      + `${attrs.class || ''} ${isVerticalStack ? 'w-full' : ''}`
      + `${props.padded ? ' px-4 py-1.5 clickable' : ' cursor-pointer'}`;

    const labelSpanClasses = 'pr-4 first:pl-6';
    const hintDotClasses = 'w-2 h-2 rounded-full bg-primary absolute left-1 top-1/2 -mt-1';

    store.watch(
      (state) => state.user,
      (newValue) => {
        user.value = newValue;
      },
    );

    const hasPermission = computed(() => {
      if (!user.value) return false;
      if (props.permissions === '') return true;
      const permissionsArray = props.permissions.split(' ');
      return permissionsArray.some((permission) => user.value.allowed(permission));
    });

    const toggleSubmenu = () => {
      isSubmenuOpen.value = !isSubmenuOpen.value;
    };

    const closeMenu = (event: MouseEvent) => {
      if (menuRef.value && menuMainRef.value && !menuMainRef.value.contains(event.target as Node)) {
        isSubmenuOpen.value = false;
      }
    };

    const scheduleCloseMenu = () => {
      if (closeMenuTimeout.value !== null) {
        clearTimeout(closeMenuTimeout.value);
      }
      closeMenuTimeout.value = setTimeout(() => {
        isSubmenuOpen.value = false;
      }, 800) as unknown as number;
    };

    const cancelCloseMenu = () => {
      if (closeMenuTimeout.value !== null) {
        clearTimeout(closeMenuTimeout.value);
        closeMenuTimeout.value = null;
      }
    };

    onMounted(() => {
      document.addEventListener('click', closeMenu);
    });

    onUnmounted(() => {
      document.removeEventListener('click', closeMenu);
      if (closeMenuTimeout.value !== null) {
        clearTimeout(closeMenuTimeout.value);
      }
    });

    const checkMenuAlignment = () => {
      if (menuHolderRef.value) {
        const menuRect = menuHolderRef.value.getBoundingClientRect();
        const spaceOnRight = window.innerWidth - menuRect.right;
        shouldAlignRight.value = spaceOnRight < menuRect.width;
      }
    };

    const handleClick = () => {
      if (!props.to && !slots.submenu) {
        emit('click');
      }
    };

    onMounted(() => {
      window.addEventListener('resize', checkMenuAlignment);
      checkMenuAlignment();
    });
    onUnmounted(() => {
      window.removeEventListener('resize', checkMenuAlignment);
    });

    return {
      ...toRefs(props),
      hasPermission,
      classes,
      isSubmenuOpen,
      toggleSubmenu,
      shouldAlignRight,
      menuRef,
      scheduleCloseMenu,
      cancelCloseMenu,
      menuMainRef,
      menuHolderRef,
      handleClick,
      labelSpanClasses,
      hintDotClasses,
    };
  },
});
</script>

<template>
  <router-link
    v-if="!$slots.submenu && to && hasPermission && exact"
    :to="to"
    :class="classes"
    exact-active-class="bg-background-dark"
    exact
  >
    <div :class="hintDotClasses" v-if="hinted" />
    <div :class="[hintDotClasses, 'animate-ping']" v-if="hinted" />
    <img v-if="icon" :src="icon" alt="User" class="mr-2 last:mr-0" :class="padded ? 'w-4 h-4' : 'w-6 h-6'" />
    <span v-if="label" :class="labelSpanClasses">{{ label }}</span>
    <slot />
  </router-link>
  <router-link
    v-if="!$slots.submenu && to && hasPermission && !exact"
    :to="to"
    :class="classes"
    active-class="bg-background-dark"
  >
    <div :class="hintDotClasses" v-if="hinted" />
    <div :class="[hintDotClasses, 'animate-ping']" v-if="hinted" />
    <img v-if="icon" :src="icon" alt="User" class="mr-2 last:mr-0" :class="padded ? 'w-4 h-4' : 'w-6 h-6'" />
    <span v-if="label" :class="labelSpanClasses">{{ label }}</span>
    <slot />
  </router-link>
  <div
    v-if="!$slots.submenu && !to && hasPermission"
    :class="[
      classes,
      active ? 'bg-background-dark' : '',
      'cursor-pointer'
    ]"
    @click.stop="handleClick"
  >
    <div :class="hintDotClasses" v-if="hinted" />
    <div :class="[hintDotClasses, 'animate-ping']" v-if="hinted" />
    <img v-if="icon" :src="icon" alt="User" class="mr-2 last:mr-0" :class="padded ? 'w-4 h-4' : 'w-6 h-6'" />
    <span v-if="label" :class="labelSpanClasses">{{ label }}</span>
    <slot />
  </div>
  <div
    v-if="hasPermission && $slots.submenu && hasPermission"
    class="relative"
    ref="menuHolderRef"
    @mouseleave="scheduleCloseMenu"
    @mouseenter="cancelCloseMenu"
  >
    <div @click="toggleSubmenu"
      ref="menuMainRef"
      :class="`${classes} cursor-pointer`"
    >
      <div :class="hintDotClasses" v-if="hinted" />
      <div :class="[hintDotClasses, 'animate-ping']" v-if="hinted" />
      <img v-if="icon" :src="icon" alt="User" class="mr-2 last:mr-0" :class="padded ? 'w-4 h-4' : 'w-6 h-6'" />
      <span v-if="label" :class="labelSpanClasses">{{ label }}</span>
      <slot />
    </div>
    <div
      ref="menuRef"
      class="border border-border mx-auto p-4 bg-background rounded-lg shadow-xl z-50"
      v-if="isSubmenuOpen"
      :class="[
        'absolute mt-2',
        shouldAlignRight ? 'origin-top-right right-0' : 'origin-top-left left-0'
      ]"
    >
      <VerticalStack :spacing="null">
        <slot name="submenu" />
      </VerticalStack>
    </div>
  </div>
</template>
