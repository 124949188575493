<template>
  <div class="content-card p-6">
    <slot></slot>
  </div>
</template>

<script lang="ts">
export default {
  name: 'CardElement',
};
</script>
