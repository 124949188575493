<template>
  <div class="flex flex-col"
    :class="[spacingClass, {
      'items-start': alignment === 'left',
      'items-center': alignment === 'center',
      'items-end': alignment === 'right',
    }]"
  >
    <slot />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, provide } from 'vue';
import { getSpacingClass, Spacing } from '@/router/spacing-helpers';

export default defineComponent({
  name: 'VerticalStack',
  props: {
    alignment: {
      type: String as PropType<'left' | 'center' | 'right'>,
      default: 'left',
    },
    spacing: {
      type: Number as PropType<Spacing | null>,
      default: 4,
    },
  },
  setup(props) {
    provide('isVerticalStack', true);

    const spacingClass = computed(() => {
      if (props.spacing === null) {
        return '';
      } else {
        return getSpacingClass(props.spacing, 'y');
      }
    });
    return {
      spacingClass,
    };
  },
});
</script>
