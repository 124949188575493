import axios, { isAxiosError } from 'axios';
import { api, azure } from '@/config';
import store from '@/store/store';
import OrganizationMember from '@/types/OrganizationMember';
import { PageResult, EmptyPageResult } from '@/types/PageResult';
import { Organization } from '@/types/Organization';
import { OrganizationSettings } from '@/types/OrganizationSettings';
import { sleep } from '@/utils/threading';

const OrganizationService = {

  async getSettings(slug: string): Promise<OrganizationSettings> {
    return (await axios.get<OrganizationSettings>(`${api.v1}/${slug}/settings`)).data;
  },

  async updateSettings(
    slug: string,
    autoSeatAssignment?: boolean,
    maxSeatsPerUser?: number,
    addinSettings?: {
      imagesHidden: boolean;
      onlineImagesHidden: boolean;
      presentationsHidden: boolean;
      slidesHidden: boolean;
      iconsHidden: boolean;
      animatedIconsHidden: boolean;
    },
    ) {
    await axios.patch(`${api.v1}/${slug}/settings`, {
      autoSeatAssignment,
      maxSeatsPerUser,
      addinSettings
    });
  },

  async createOrganization(
    name: string | null,
    mergeData: boolean,
    logoFile?: File | null,
    fromAzureSync = false,
  ) : Promise<string> {
    const formData = new FormData();
    formData.append('createOrganizationRequest', new Blob([JSON.stringify({
      name,
      mergeData,
      fromAzureSync,
    })], {
      type: 'application/json',
    }));
    if (logoFile) {
      formData.append('logo', logoFile);
    }

    return (await axios.post<string>(`${api.v1}/organizations`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })).data;
  },

  async uploadLogo(logoFile: File | null) {
    const slug = store.getters.organizationSlug;
    if (!slug) {
      return;
    }

    if (logoFile) {
      const formData = new FormData();
      formData.append('file', logoFile);
      return await axios.put<string>(`${api.v1}/${slug}/logo`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } else {
      return await axios.delete(`${api.v1}/${slug}/logo`);
    }
  },

  async updateOrganization(
    displayName: string,
  ) {
    const slug = store.getters.organizationSlug;
    if (!slug) {
      return;
    }

    await axios.patch(`${api.v1}/${slug}`, { displayName });
  },

  async inviteUser(email: string) {
    const slug = store.getters.organizationSlug;
    if (!slug) {
      return;
    }

    await axios.post(`${api.v1}/${slug}/invite`, { email });
  },

  async getMembers(
    page: number,
    size: number,
  ): Promise<PageResult<OrganizationMember>> {
    const slug = store.getters.organizationSlug;
    if (!slug) {
      return EmptyPageResult.empty<OrganizationMember>();
    }

    const params = new URLSearchParams();
    params.append('page', page.toString());
    params.append('size', size.toString());
    params.append('sort', 'firstName,asc');
    params.append('sort', 'lastName,asc');

    const response = await axios.get<PageResult<OrganizationMember>>(
      `${api.v1}/${slug}/members`,
      {
        params,
      },
    );
    return response.data;
  },

  async getOrganizationInfo(): Promise<Organization> {
    const slug = store.getters.organizationSlug;
    if (!slug) {
      return {
        name: '',
        displayName: '',
        slug: '',
        azureTenant: null,
      };
    }

    const response = await axios.get<Organization>(`${api.v1}/${slug}`);
    return response.data;
  },

  async getPendingInvitations(): Promise<OrganizationMember[]> {
    const slug = store.getters.organizationSlug;
    if (!slug) {
      return [];
    }

    const response = await axios.get<OrganizationMember[]>(`${api.v1}/${slug}/pending_invitations`);
    return response.data;
  },

  async getAdminConsentId(): Promise<string> {
    return (await axios.get<string>(`${api.v1}/${store.getters.organizationSlug}/azure-sync/admin-consent-id`)).data;
  },

  async startAdminConsentFlow(mergeData: boolean | null) {
    let adminConsentId: string;
    if (mergeData === null) {
      adminConsentId = (await axios.get<string>(`${api.v1}/${store.getters.organizationSlug}/azure-sync/admin-consent-id`)).data;
    } else {
      adminConsentId = await this.createOrganization(null, mergeData, null, true);
    }

    const clientId = azure.clientId;
    const authority = 'https://login.microsoftonline.com/organizations';
    const redirectUri = encodeURIComponent(azure.redirectUris.adminConsent);
    const scope = encodeURIComponent('https://graph.microsoft.com/.default');
    const state = encodeURIComponent(adminConsentId);

    const url = `${authority}/v2.0/adminconsent?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${state}`;

    window.location.href = url;
  },

  async handleAdminConsentResponse(
    tenantId: string,
    adminConsentId: string | null,
    groupId: string | null,
  ): Promise<string> {
    const consentId = adminConsentId || (await this.getAdminConsentId());

    return (await axios.post(`${api.v1}/organizations/azure-sync-consent`, {
      tenantId,
      adminConsentId: consentId,
      azureGroupId: groupId,
    })).data;
  },

  async startAzureSync() {
    const slug = store.getters.organizationSlug;
    if (!slug) {
      return;
    }

    return (await axios.post<String>(`${api.v1}/${slug}/azure-sync/start`)).data;
  },

  async getLastAzureSyncStatus(): Promise<AzureSync | null> {
    const slug = store.getters.organizationSlug;
    if (!slug) {
      return null;
    }

    try {
      return (await axios.get<AzureSync>(`${api.v1}/${slug}/azure-sync/last`)).data;
    } catch (error) {
      if (isAxiosError(error) && error.response?.status === 404) {
        return null;
      }
      throw error;
    }
  },

  async getLastSuccessfulAzureSyncStatus(): Promise<AzureSync | null> {
    const slug = store.getters.organizationSlug;
    if (!slug) {
      return null;
    }

    try {
      return (await axios.get<AzureSync>(`${api.v1}/${slug}/azure-sync/last/success`)).data;
    } catch (error) {
      if (isAxiosError(error) && error.response?.status === 404) {
        return null;
      }
      throw error;
    }
  },

  async changeAzureGroupId(groupId: string | null) {
    const slug = store.getters.organizationSlug;
    if (!slug) {
      return;
    }

    await axios.patch(`${api.v1}/${slug}/azure-sync/group-id`, { azureGroupId: groupId });
  },

  async forceDelayedReloadSettings() {

    await sleep(1000);
    await this.forceDelayedReloadSettings();
  },

  async forceReloadSettings() {
    const slug = store.getters.organizationSlug;
    if (!slug) {
      return;
    }

    await store.dispatch('loadOrganizationSettings', slug);
  }
}

export default OrganizationService;
