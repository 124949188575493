<template>
  <div class="flex flex-row h-full">
    <div
      class="content-card ml-4 mr-2 mb-4"
      :class="[
        menuSize === 'small' ? 'w-72' : 'min-w-1/3 w-1/3',
        'overflow-auto flex-shrink-0'
      ]"
    >
      <VerticalStack class="p-6" :spacing="null">
        <slot class="flex-grow" name="menu" />
      </VerticalStack>
    </div>
    <div
      class="flex-grow content-card mr-4 ml-2 mb-4"
      :class="{
        'overflow-auto': contentScrollable,
        'overflow-hidden': !contentScrollable,
      }"
    >
      <div
        class="p-6"
        :class="{
          'h-full': !contentScrollable,
        }"
      >
        <slot name="content" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import VerticalStack from '@/components/layout/VerticalStack.vue';

export default defineComponent({
  components: { VerticalStack },
  props: {
    menuSize: {
      type: String as PropType<'small | large'>,
      default: 'small',
      required: false,
    },
    contentScrollable: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  setup() {
    return {
      // Add your code here
    };
  },
});
</script>
