<template>
  <Strip :size="size" :spacing="spacing">
    <slot />
  </Strip>
</template>
<script setup lang="ts">
import Strip from '@/components/layout/Strip.vue';
import { PropType } from 'vue';
import { Spacing } from '@/router/spacing-helpers';

defineProps(
  {
    size: {
      type: String as PropType<'small' | 'large'>,
      default: 'large',
    },
    spacing: {
      type: Number as PropType<Spacing>,
      default: 2,
    },
  }
);
</script>
