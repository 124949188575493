<template>
  <teleport to="body">
    <OverlayBackground class="z-50">
      <div class="border border-border mx-auto bg-background rounded-lg shadow-2xl
      my-auto w-5/6 p-0"
      >
        <slot />
      </div>
    </OverlayBackground>
  </teleport>
</template>
<script setup lang="ts">
import OverlayBackground from '@/components/layout/OverlayBackground.vue';
</script>
