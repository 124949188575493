import axios from 'axios';
import User from '@/types/User';
import TwoFAActivationRequestResult from '@/types/TwoFAActivationRequestResult';
import TwoFAActivationVerificationResult from '@/types/TwoFAActivationVerificationResult';
import { api } from '../config';
import store from '@/store/store';
import { inOfficeAddin } from '@/office-checker';
import { LocalSettingsService } from '@/services/LocalSettingsService';

class MeService {
  static async me(): Promise<User> {
    const response = await axios.get<User>(`${api.v1}/me`);
    return new User(response.data);
  }

  static async editMe(
    firstName: string | null,
    lastName: string | null,
  ): Promise<User> {
    const response = await axios.patch<User>(
      `${api.v1}/me`,
      { firstName, lastName },
    );
    return new User(response.data);
  }

  static async changePassword(
    currentPassword: string,
    newPassword: string,
  ): Promise<void> {
    await axios.patch(`${api.v1}/me/password`, {
      currentPassword,
      newPassword,
    });
  }

  static async logout(): Promise<boolean> {
    await axios.post(`${api.v1}/me/logout`);

    await LocalSettingsService.setLoginStateIntention('intentionallyLoggedOut');

    if ((store.state.user as unknown as User).azureSSO && !inOfficeAddin) {
      await MeService.logoutFromMicrosoft();
      return false;
    }
    return true;
  }

  static async verifyEmail(token: string): Promise<void> {
    await axios.post(`${api.v1}/me/verify`, { token });
  }

  static async resendAfter(): Promise<Date> {
    const { data: { resendAfter } } = await axios.get(`${api.v1}/me/verify/resend-after`);
    return new Date(resendAfter);
  }

  static async resendVerificationEmail(): Promise<void> {
    await axios.post(`${api.v1}/me/verify/resend`);
  }

  static async request2FAActivation(activate: boolean, type: 'EMAIL' | 'TOTP'): Promise<TwoFAActivationRequestResult> {
    const response = await axios.post<TwoFAActivationRequestResult>(
      `${api.v1}/me/settings/2fa/${activate ? 'activate' : 'deactivate'}/request`,
      { type },
    );
    return response.data;
  }

  static async activate2FA(activate: boolean, token: string, type: 'EMAIL' | 'TOTP'): Promise<TwoFAActivationVerificationResult> {
    const response = await axios.post<TwoFAActivationVerificationResult>(`${api.v1}/me/settings/2fa/${activate ? 'activate' : 'deactivate'}/verify`, {
      token,
      type,
    });
    return response.data;
  }

  private static async logoutFromMicrosoft(): Promise<void> {
    const authority = 'https://login.microsoftonline.com/common';

    window.location.href = `${authority}/oauth2/v2.0/logout`;
  }
}

export default MeService;
