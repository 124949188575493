<template>
  <auth-view-holder
    :title="t('screens.registration.data.title')">
    <form @submit.prevent="register">
      <VerticalStack alignment="center">
        <labeled-default-input
          class="w-full"
          :label="$t('screens.registration.data.firstName.label')"
          v-model="firstName"
          type="text"
          :error="onceSent && firstName === ''" />
        <labeled-default-input
          class="w-full"
          :label="$t('screens.registration.data.lastName.label')"
          v-model="lastName"
          type="text"
          :error="onceSent && lastName === ''" />
        <labeled-default-input
          class="w-full"
          :label="$t('screens.registration.data.email.label')"
          v-model="email"
          type="email"
          :error="onceSent && email === ''" />
        <labeled-default-input
          class="w-full"
          :label="$t('screens.registration.data.password.placeholder')"
          v-model="password"
          type="password"
          :error="onceSent && password === '' || passwordRulesNotFit || passwordsDoNotMatch"
          :info="$t('screens.registration.data.password.info')"
        />
        <labeled-default-input
          class="w-full"
          :label="$t('screens.registration.data.passwordConfirmation.label')"
          v-model="passwordConfirmation"
          type="password"
          :error="onceSent && passwordConfirmation === '' || passwordsDoNotMatch" />
        <div id="recaptcha-holder" />
      </VerticalStack>
      <default-button
        actionType="submit"
        :label="t('screens.registration.data.buttons.register')"
        class="w-full mt-8"
        auto-loading-state="global"
      />
      <div class="w-full text-center mt-6">
        <default-button to-route="/login" auto-loading-state="global">
          {{ $t('screens.registration.data.links.login') }}
        </default-button>
      </div>
    </form>
  </auth-view-holder>

</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import AuthService from '@/services/AuthService';
import { isAxiosError } from 'axios';
import AuthViewHolder from '@/components/layout/AuthViewHolder.vue';
import DefaultButton from '@/components/controls/DefaultButton.vue';
import VerticalStack from '@/components/layout/VerticalStack.vue';
import useMessages from '@/composables/use-messages';
import { useRouter } from 'vue-router';
import { loadRecaptcha } from '@/utils/recaptcha-helper';
import useLoadingState from '@/composables/use-loading-state';

export default defineComponent({
  name: 'LoginView',
  components: {
    VerticalStack,
    DefaultButton,
    AuthViewHolder,
  },
  setup() {
    const firstName = ref('');
    const lastName = ref('');
    const email = ref('');
    const password = ref('');
    const passwordConfirmation = ref('');
    const passwordRulesNotFit = ref(false);
    const passwordsDoNotMatch = ref(false);
    const recaptchaToken = ref('');

    const store = useStore();
    const router = useRouter();
    const { t } = useI18n();
    const { startLoading, stopLoading } = useLoadingState();

    const onceSent = ref(false);

    let loadedPasswordRulesPromise: Promise<string> | null = null;

    const { setErrorMessage } = useMessages();

    const register = async () => {
      onceSent.value = true;
      if (
        firstName.value === ''
        || lastName.value === ''
        || email.value === ''
        || password.value === ''
        || passwordConfirmation.value === ''
      ) {
        setErrorMessage(t('screens.registration.data.errors.emptyFields'));
        return;
      }

      if (password.value !== passwordConfirmation.value) {
        setErrorMessage(t('screens.registration.data.errors.passwordMismatch'));
        passwordsDoNotMatch.value = true;
        return;
      }

      passwordsDoNotMatch.value = false;

      if (!recaptchaToken.value) {
        setErrorMessage(t('screens.registration.data.errors.captcha'));
        return;
      }

      const rules = await loadedPasswordRulesPromise;

      if (!rules) {
        setErrorMessage(t('errors.unknown'));
        return;
      }

      if (!AuthService.checkPassword(password.value, rules)) {
        setErrorMessage(t('screens.registration.data.errors.passwordRules'));
        passwordRulesNotFit.value = true;
        return;
      }

      passwordRulesNotFit.value = false;

      await startLoading();

      AuthService.register(email.value, password.value, firstName.value, lastName.value, recaptchaToken.value, router)
        .catch((error) => {
          if (isAxiosError(error) && error.response?.status === 409) {
            setErrorMessage(t('screens.registration.data.errors.emailTaken'));
          } else if (isAxiosError(error) && error.response?.status === 400) {
            setErrorMessage(t('screens.registration.data.errors.captchaServer'));
            initializeRecaptcha();
          } else {
            setErrorMessage(t('errors.unknown'));
          }
        }).finally( async () => {
          await stopLoading();
        });
    };

    onMounted(() => {
      loadedPasswordRulesPromise = AuthService.getPasswordRules();

      initializeRecaptcha();
    });

    const initializeRecaptcha = () => {
      loadRecaptcha(
        'recaptcha-holder',
        (token) => recaptchaToken.value = token,
        () => {
          recaptchaToken.value = '';
          setErrorMessage('screens.registration.data.errors.captchaTimeout');
        },
        () => {
          recaptchaToken.value = '';
          setErrorMessage('screens.registration.data.errors.captchaClient');
        }
      );
    };

    watch(recaptchaToken, (newValue) => {
      console.log(newValue);
    });

    return {
      firstName,
      lastName,
      email,
      password,
      passwordConfirmation,
      register,
      t,
      onceSent,
      passwordRulesNotFit,
      passwordsDoNotMatch,
    };
  },
});
</script>
