<template>
  <ModalView
    :title="modal.title"
    @close="removeModal"
    :size="modal.size"
    :show-close-button="modal.showCloseButton"
    :show-menu="modal.showMenu"
    :close-on-escape="modal.closeOnEsc"
  >
    <component :is="loadModalComponent" v-bind="modal.props" />
  </ModalView>
</template>

<script lang="ts">
import { computed, defineAsyncComponent, defineComponent } from 'vue';
import ModalView from '@/components/layout/ModalView.vue';
import ModalInfo from '@/types/ModalInfo';
import useModal from '@/composables/use-modal';

export default defineComponent({
  components: { ModalView },
  props: {
    modal: {
      type: Object as () => ModalInfo,
      required: true,
    },
  },
  setup(props) {
    const loadModalComponent = computed(() => (props.modal.content
      ? defineAsyncComponent(() => import(`@/${props.modal.content}.vue`))
      : null));

    const { closeModal } = useModal();

    const removeModal = () => {
      closeModal();
    };

    return {
      loadModalComponent,
      removeModal,
    };
  },
});
</script>
