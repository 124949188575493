<template>
  <hr class="border-foreground-ultraLight mt-0.5" />
</template>

<script lang="ts">

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SimpleDivider',
});

</script>
