import axios from 'axios';
import { api } from '@/config';
import AppConfig from '@/types/AppConfig';
import { ActionContext } from 'vuex';

// State type definition
interface AppConfigState {
  appConfig: AppConfig | null;
}

// Initial state
const state: AppConfigState = {
  appConfig: null
};

// Getters
const getters = {
  maxDisplayNameLength: (state: AppConfigState): number => {
    return state.appConfig ? state.appConfig.maxDisplayNameLength : 0;
  },
  maxUnfoldedFiles: (state: AppConfigState): number => {
    return state.appConfig ? state.appConfig.maxUnfoldedFiles : 0;
  },
  allowedImageTypes: (state: AppConfigState): string => {
    return state.appConfig ? state.appConfig.allowedImageTypes : '';
  },
  allowedPresentationTypes: (state: AppConfigState): string => {
    return state.appConfig ? state.appConfig.allowedPresentationTypes : '';
  },
  allowedSlidesTypes: (state: AppConfigState): string => {
    return state.appConfig ? state.appConfig.allowedSlidesTypes : '';
  },
  allowedIconTypes: (state: AppConfigState): string => {
    return state.appConfig ? state.appConfig.allowedIconTypes : '';
  },
  searchDebounceTime: (state: AppConfigState): number => {
    return state.appConfig ? state.appConfig.searchDebounceTime : 300;
  },
  numberOfLastUsedFiles: (state: AppConfigState): number => {
    return state.appConfig ? state.appConfig.numberOfLastUsedFiles : 0;
  },
  allowedAnimatedIconTypes: (state: AppConfigState): string => {
    return state.appConfig ? state.appConfig.allowedAnimatedIconTypes : '';
  },
  backendVersionInfo: (state: AppConfigState): string => {
    if (!state.appConfig) {
      return '';
    }
    const backendVersion = state.appConfig.backendVersion ?? '-';
    const backendBuildNumber = state.appConfig.backendBuildNumber ?? '-';
    return `${backendVersion} (${backendBuildNumber})`;
  },
  lowerTilingBreakpoint: (state: AppConfigState): number => {
    return state.appConfig ? state.appConfig.lowerTilingBreakpoint : 0;
  },
  upperTilingBreakpoint: (state: AppConfigState): number => {
    return state.appConfig ? state.appConfig.upperTilingBreakpoint : 0;
  },
};

// Mutations
const mutations = {
  SET_APP_CONFIG(state: AppConfigState, appConfig: AppConfig) {
    state.appConfig = appConfig;
  }
};

type AppConfigActionContext = ActionContext<AppConfigState, any>;

// Actions
const actions = {
  async loadAppConfig({ commit }: AppConfigActionContext) {
    const appConfig = (await axios.get<AppConfig>(`${api.v1}/config`)).data;
    commit('SET_APP_CONFIG', appConfig);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
