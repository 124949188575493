import { AxiosError } from 'axios';

function isAxiosError(error: any): error is AxiosError {
  return (error as AxiosError).isAxiosError;
}

function isAxiosErrorWithCode(error: any, code: number): error is AxiosError {
  return (error as AxiosError).isAxiosError && (error as AxiosError).response?.status === code;
}

export { isAxiosError, isAxiosErrorWithCode };
